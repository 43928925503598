import React, { useEffect, useState } from 'react'
import { Modal, Form, Col, InputGroup, Button, Spinner } from 'react-bootstrap'
import axios from 'axios'
import { endpoint, getDepartment } from '../../../utils/api';
import { Chip, Avatar } from '@mui/material';
import SaveButton from '../../../elements/SaveButton';
//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature, faHistory, faUserShield, faUser, faSave, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

//CSS
import '../../../css/icons/stylingIcons.css'
import '../../../css/components/departments.css'
import MembersSidebar from './MembersSidebar';

library.add(faFileSignature, faHistory, faUserShield, faSave, faUser)

const EditDepartmentModal = ({ users, departmentID, editdepartment, openEditDepartment, departmentUpdated, departmentError, callDepartments }) => {

    const [department, setDepartment] = useState({});
    const [updatedMembers, setUpdatedMembers] = useState([]);
    const [updatedAdmins, setUpdatedAdmins] = useState([]);
    const [duplicate, setDuplicate] = useState("");
    const [hover, setHover] = useState("");
    const [loading, setLoading] = useState(false)
    const [slashAlert, setSlashAlert] = useState(false)

    useEffect(() => {
        getDepartment(setDepartment, departmentID);
    }, [])    

    useEffect(() => {
        if (department.admins !== undefined) {

            department.admins.map( a => {
                setUpdatedAdmins( prevAdmins => {
                    return [...prevAdmins, a.name]
                })
            })
    
            department.members.map( m => {
                setUpdatedMembers( prevMembers => {
                    return [...prevMembers, m.name]
                })
            })
        }       
    }, [department])

    useEffect(() => {
        setDuplicate("");
        updatedAdmins.map(a => {
            if (updatedMembers.includes(a)){
                setDuplicate(a);
            } 
        })
    }, [updatedMembers , updatedAdmins])
    
    const handleTitleChange = (title) => {
        title.includes("/") ? setSlashAlert(true) : setSlashAlert(false)
    }   

    let usersArr = []
    Object.keys(users).map(user => {
        usersArr.push(users[user].name)
    })

    const [validated, setValidated] = useState(false)

    const deletion = [
        {
            label: "Yes",
            name: false
        },
        {
            label: "No",
            name: true
        }
    ]

    const handleSubmit = async (e, props) => {
        const form = e.currentTarget
        setLoading(true)
        if (form.checkValidity() === false || slashAlert || duplicate !== "") {
            e.preventDefault()
            e.stopPropagation()
            setValidated(true)
            setLoading(false)
        } else {
            e.preventDefault()

            const formData = new FormData(e.target),
            formDataObj = Object.fromEntries(formData.entries())

            await axios({
                method: "PUT",
                url: `${endpoint}/departments`, 
                data: {
                    name: formDataObj.name,
                    members: updatedMembers,
                    admins: updatedAdmins,
                    department_id: formDataObj.department_id,
                    is_deleted: formDataObj.is_deleted
                } ,
                headers: {
                    Authorization: 'Bearer '+ localStorage.token,
                    Department: departmentID
                }
            })
                .then(function (response) {
                    if (response.status === 200){               
                        departmentUpdated(true)
                        openEditDepartment(false)
                        callDepartments();
                        setLoading(false)
                    } else {
                        console.log(response.statusText)  
                        console.log(response.errors)   
                        departmentError("Error")   
                        openEditDepartment(false)    
                        setLoading(false)        
                    }
                })
                .catch((error) => {
                    console.log(error)
                    departmentError(error.response.data)   
                    openEditDepartment(false)
                    setLoading(false)
                })

        }
    }
    return (
        <Modal show={editdepartment} onHide={() => openEditDepartment(false)}>
            <Modal.Header className="heading" closeButton>
                <Modal.Title>Edit Department</Modal.Title>
            </Modal.Header>

            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Modal.Body>

                <Form.Control type="hidden" name="department_id" defaultValue={departmentID}/>

                        <Form.Group as={Col} controlId="formGridDate">
                            <Form.Label>Name</Form.Label>
                            <InputGroup className="mb-2" onChange={(e) => {handleTitleChange(e.target.value)}}>
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FontAwesomeIcon icon={faFileSignature}  size='1x' className='all-font-icons' /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="Name..." name="name" defaultValue={department.name} required />
                                <Form.Control.Feedback type="invalid">
                                    Please choose a name.
                                </Form.Control.Feedback>
                            </InputGroup>
                            {slashAlert && 
                                <h5 className="no-category">            
                                    <FontAwesomeIcon 
                                    icon={faExclamation}  
                                    size='1x' 
                                    className='all-font-icons no-tags-icon' 
                                    />
                                        Category names containing "/" are prohibited
                                </h5>  
                            }
                        </Form.Group>  

                        <Form.Group as={Col} controlId="formGridDate">
                        <Form.Label>Admins</Form.Label>
                            <div>
                            {updatedAdmins.map(admin => {
                                 return <Chip 
                                        className={admin === hover ? "admin-chip chip-hover" : "admin-chip"} 
                                        avatar={
                                        <Avatar className={admin === hover ? "avatar-icon avatar-hover" : "avatar-icon" }>
                                            <FontAwesomeIcon icon={faUserShield}  
                                            size='1x' 
                                            className='all-font-icons department-chip-icon' 
                                            />
                                        </Avatar>
                                        } 
                                        label={admin}
                                        onMouseEnter={(e) => setHover(e.target.innerText)}
                                        onMouseLeave={() => setHover("")}
                                        />
                            })
                            }
                            </div>            
                            <MembersSidebar usersArr={usersArr} membersArr={updatedAdmins} setUpdatedMembers={setUpdatedAdmins} type={"admins"}/>
                        </Form.Group>      

                        <Form.Group as={Col} controlId="formGridDate">
                        <Form.Label>Members</Form.Label>
                            <div>
                            {updatedMembers.map(member => {
                                return <Chip 
                                        className={member === hover ? "member-chip chip-hover" : "member-chip"}
                                        avatar=
                                        {
                                        <Avatar className={member === hover ? "avatar-icon avatar-hover" : "avatar-icon" }>
                                            <FontAwesomeIcon icon={faUser}  
                                                size='1x' 
                                                className='all-font-icons department-chip-icon' />
                                        </Avatar>
                                        } 
                                        label={member} 
                                        onMouseEnter={(e) => setHover(e.target.innerText)}
                                        onMouseLeave={() => setHover("")}
                                        />
                            })
                            }
                            </div>
              
                            <MembersSidebar usersArr={usersArr} membersArr={updatedMembers} setUpdatedMembers={setUpdatedMembers} type={"members"}/>
                            {duplicate !== "" && <p className="duplicate-warning"> {duplicate} cannot be an admin and a member.</p>}    
                        </Form.Group> 

                                       

                {department.is_deleted === true && (
                         <Form.Group as={Col} controlId="formGridContactName">
                         <Form.Label>Change Disabling?</Form.Label>
                         <InputGroup className="mb-2">
                             <InputGroup.Prepend>
                                 <InputGroup.Text><FontAwesomeIcon icon={faHistory}  size='1x' className='all-font-icons' /></InputGroup.Text>
                             </InputGroup.Prepend>
                                 <Form.Control as="select" name="is_deleted" >
                                     {deletion.map((option) => {                                                                                  
                                        return <option selected={option.name} value={option.name}>{option.label}</option>
                                     })}                             
                                 </Form.Control>
                         </InputGroup>
                     </Form.Group> 
                     
                )}    

                </Modal.Body>

                <Modal.Footer className="footer">
                    <Button variant="secondary" onClick={() => openEditDepartment(false)}>Close</Button>
                    <Button className="save-button primary-button border-button" type="submit" disabled={loading || slashAlert || duplicate !== ""}>
                        {loading ? 
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="light"     
                            />
                            :
                            <>
                            <FontAwesomeIcon icon={faSave}  size='1x' className='all-font-icons' />  Save
                            </>
                        }          
                    </Button> 
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default EditDepartmentModal