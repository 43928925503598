import React, { useState } from 'react'
import { Row, Col, Alert } from 'react-bootstrap'

const AlertComponent = ({ type, text, setShow }) => {

    return (
        <Row>
            <Col md={12}>
            <Alert variant={type} onClose={() => setShow(false)} dismissible>
                {text}
            </Alert>
            </Col>
        </Row>
    ) 
}

export default AlertComponent