import { Container, Row, Col, Button } from 'react-bootstrap'
import { endpoint, getSection } from '../../utils/api';  
import { useState, useEffect } from 'react';  
import RevisionCardDeleted from './RevisionCardDeleted';
import ScrollContainer from 'react-indiana-drag-scroll'

const PreviewSection = ({sectionID, chosenDepartment}) => {

    const [revisions, setRevisions] = useState([]); 
    const [section, setSection] = useState([]);
    const [revisionSelected, setRevisionSelected] = useState(null);


    async function getRevisions()

    {
        //load section
        let response = await fetch(`${endpoint}/sections/section/${sectionID}/revisions`, 
         {
        headers: {
            Authorization: 'Bearer '+ localStorage.token,
            departmentName: chosenDepartment
        }
        }
        );        
       
        let j = await response.json();
    
        setRevisions(j);
    }

    useEffect(() => {
        chosenDepartment !== null && getSection(setSection, sectionID, chosenDepartment);
    },[chosenDepartment, sectionID])

    useEffect(() => {
        sectionID !== undefined && getRevisions()
    }, [sectionID])

    useEffect(() => {
        if (revisions.length !==0) {
            section.revision !== undefined && revisions.map(r => { if (r.revision === section.revision) { setRevisionSelected( r._id) } }) 
        } else {
            setRevisionSelected(sectionID)
        }
        
    }, [revisions])

    return (
        <Container style={{paddingLeft: '0'}}>
                <ScrollContainer 
                    className="deleted-section-revisions-box" 
                    vertical={false} 
                    horizontal={true} 
                    hideScrollbars={false} 
                    nativeMobileScroll={true}
                    children={
                   
                        revisions.map(r => {
                            return  (                   
                                  <RevisionCardDeleted revision={r} section={section} setRevisionSelected={setRevisionSelected} revisionSelected={revisionSelected} />              
                          
                            )
                            })
                      
                    }>
            
                </ScrollContainer>       
   

            <Row style={{marginTop: '5px'}}>           
            <iframe src={"https://view.officeapps.live.com/op/embed.aspx?src=" + "http://machreporter.co.uk/sections/" + revisionSelected + "/doc"}  width="100%" height="698" frameborder='0'>This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe>
            </Row>

        </Container>
    )
}


export default PreviewSection

