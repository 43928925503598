/* eslint-disable no-extend-native */
import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Form, ButtonGroup } from 'react-bootstrap'
import AlertComponent from '../alerts/AlertComponent'
import TemplatesTable from './TemplatesTable'
import { endpoint, getTemplates, getUsers, getCategories } from '../../utils/api'
import RemoveTemplateModal from './RemoveTemplateModal'
import makeAnimated from 'react-select/animated';
import { checkUserToken, CheckUserRole } from "../../utils/checkUser";
import { useHistory } from "react-router-dom";
import {FormControl, InputLabel, InputAdornment, FilledInput, Select, Box, MenuItem } from '@mui/material'
import TemplateWidget from './TemplateWidget'

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faShareSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faShareSquare)
const animatedComponents = makeAnimated();

const Templates = ({chosenDepartment, departmentPermission}) => {
    const history = useHistory();
    const [removetemplatesection, openRemoveTemplate] = useState(false)

    const [templatecreated, setTemplateCreated] = useState(false);
    const [templateerror, setTemplateError] = useState(false);
    const [templatedeleted, setTemplateDeleted] = useState(false);
    const [users, setUsers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [chosenView, setChosenView] = useState("card");
    const [chosenCategory, setChosenCategory] = useState("All");
    const [templates, setTemplates] = useState([]);
    const [templatesVisible, setTemplatesVisible] = useState([]);
    const [searchText, setSearchText] = useState("");

    const [templateID, setTemplateID] = useState('');
    const{role} = CheckUserRole();

    useEffect(() => {    
        getUsers(setUsers);
        checkUserToken();
    }, []);

    useEffect(() => {
        if (chosenDepartment !== "" && chosenDepartment !== undefined) {
            getTemplates(setTemplates, chosenDepartment, chosenCategory);
            getCategories(setCategories, chosenDepartment);
        }
    }, [chosenDepartment])


    useEffect(() => {
        if (chosenDepartment !== "" && chosenDepartment !== undefined) {
            getTemplates(setTemplates, chosenDepartment, chosenCategory)
        }

    }, [chosenCategory])
    
    //Filter Level 1
    useEffect(() => {
        setTemplatesVisible(
            templates.filter((template) => {
            return(
                template.name.toLowerCase().includes(searchText.toLowerCase()) ||
                template.description.toLowerCase().includes(searchText.toLowerCase())
                );
            })
        )          
    }, [searchText, templates]);

    const openTemplate = (ID) => {
        history.push(`/templates/${chosenDepartment}/All/${ID}`);
    }

    return (
        <Container className="main-page" fluid>            
            {templatecreated && <AlertComponent type="success" text="Report Created!"/>}
            {templateerror && <AlertComponent type="danger" text={templateerror}/>}
            {templatedeleted && <AlertComponent type="danger" text="Report Deleted!"/>}

            <Row className="row-header">
                    <Col sm={8} xs={9}>
                        <Container>
                            <h2 className="main-header">{chosenDepartment} Templates</h2>
                        </Container>
                    </Col>
                    <Col sm={4} xs={3} className="buttons">
                        <Container>
                            {departmentPermission &&
                                <Button className="primary-button  border-button" onClick={() => openTemplate("New")} >
                                    <FontAwesomeIcon icon={faPlusSquare}  size='1x' className='all-font-icons' />  New
                                </Button>
                            }
                        </Container>
                    </Col>
            </Row>

            <Container fluid>
                    <hr style={{backgroundColor: '#C8C8C8', width: '100%', height: '2px', marginTop: '0'}}></hr> 
            </Container> 

            <Container fluid >
                <Row style={{marginBottom: "15px", paddingLeft: "15px"}}>

                    <Col lg={6} md={12} sm={12} xs={12} className="view-col">
                            <div className="card-view-button view-button" style={{fontWeight: chosenView === "card" && "550"}} onClick={() => setChosenView("card")}>
                                {"Card View"}
                            </div>
                            <div className="view-button" style={{fontWeight: chosenView === "table" && "550"}} onClick={() => setChosenView("table")}>
                                Table View
                            </div>
                    </Col>

                    <Col lg={3} md={6} sm={6} xs={6} >
                    <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth className="category-select">
                        <InputLabel id="demo-simple-select-label" style={{fontSize: "14px"}}>Category</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={chosenCategory}
                                label="Category"
                                onChange={(e) => setChosenCategory(e.target.value)}
                                style={{fontSize: "14px"}}
                            >
                                <MenuItem value={"All"} className="category-option">All</MenuItem>
                                {categories.map(c => {
                                    return   <MenuItem value={c.title} className="category-option">{c.title}</MenuItem>
                                })}
                      
                            </Select>
                        </FormControl>
                    </Box>
                    </Col>

                    
                    <Col lg={3} md={6} sm={6} xs={6}>
                        
                        <FormControl variant="filled" style={{width: "100%", marginRight: '12px'}} onChange={(e) => setSearchText(e.target.value)} >
                                <InputLabel htmlFor="input-with-icon-adornment" className="search-placeholder">
                                    Search Name, Description, and Tags
                                </InputLabel>
                                <FilledInput
                                id="input-with-icon-adornment"
                                className="search-bar"
                                endAdornment={
                                    <InputAdornment position="end" style={{color: '#224166'}}>
                                        <FontAwesomeIcon icon={faSearch} className="fontWidgetIcon" size='1x' />
                                    </InputAdornment>
                                }
                                />
                        </FormControl>
                    </Col>
    
                </Row>
            </Container>

            <Container fluid style={{padding: 0}}>
                <Row >
                {chosenView === "card" && templatesVisible.map(t => {
                    return (
                    <Col lg={6}>
                            <TemplateWidget 
                                template={t} 
                                users={users} 
                                openTemplate={openTemplate} 
                                openRemoveTemplate={openRemoveTemplate}  
                                setTemplateID={setTemplateID} 
                                departmentPermission={departmentPermission} 
                                department={chosenDepartment}
                            />                          
                        </Col>
                        )
                    })}
                </Row>
            </Container>
            
            {chosenView === "table" && 
                <Container fluid>
                    <TemplatesTable
                        data={templatesVisible}
                        EditModal={openTemplate}
                        RemoveModal={openRemoveTemplate}
                        SetID={setTemplateID}
                        departmentPermission={departmentPermission}
                    />
                </Container>
            }
   
           
            {removetemplatesection && 
            <RemoveTemplateModal 
                templateID={templateID} 
                removetemplatesection={removetemplatesection} 
                openRemoveTemplate={openRemoveTemplate} 
                setTemplateDeleted={setTemplateDeleted} 
                setTemplateError={setTemplateError} 
                setTemplates={setTemplates}
                getTemplates={getTemplates} 
                department={chosenDepartment}
                chosenCategory={chosenCategory}
                />
            }


        </Container>
    )
}

export default Templates