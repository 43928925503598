import React, { useState , useEffect } from 'react'
import { getUsers, endpoint } from '../../../utils/api'
import { CheckUserRole, checkUserToken } from '../../../utils/checkUser'
import {Row, Col, Button, Container, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import AlertComponent from '../../alerts/AlertComponent'
import {FormControl, InputLabel, InputAdornment, FilledInput } from '@mui/material'
import UsersTable from './UsersTable'
import NewUserModal from './NewUserModal'
import RemoveUserModal from './RemoveUserModal'
import UserCard from './UserCard'
import axios from 'axios'
import '../../../css/components/admin.css'


//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faPlusSquare, faSearch } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

const Users = () => {
    const {role} = CheckUserRole();
    const [users, setUsers] = useState([]);
    const [usersFromSearch, setUsersFromSearch] = useState([])
    const [searchText, setSearchText] = useState("")
    const [newusermodal, openNewUser] = useState(false)
    const [removeusermodal, openRemoveUser] = useState(false)
    const [usercreated, userCreated] = useState(false)
    const [userupdated, userUpdated] = useState(false)
    const [userdeactivated, userDeactivated] = useState(false)
    const [usererror, userError] = useState(false)
    const [userID, setUserID] = useState('')
    const [statsArr, setStatsArr] = useState([])
    const [chosenView, setChosenView] = useState("card")


    let Ids = []
    users.map(u => {
        Ids.push(u._id)
    })


    useEffect(() => {
        getUsers(setUsers, false);
        checkUserToken()
    }, []) 

    

    useEffect(() => {
        let Ids = []
        Object.keys(users).map(u => {
         Ids.push(users[u]._id)
        })
        Ids.length > 0 && getStatistics(Ids)
    }, [users])

    const callUsers = () => {
        getUsers(setUsers)
    }

    const handleUserClick = () => {
        if (role === "User") {
            return;
        } else{
            openNewUser(true);          
        }
    }

    async function getStatistics(Ids)
    {

        await axios({
            method: "Post",
            url: `${endpoint}/users/stats/`, 
            data: {
                Ids: Ids
            } ,
            headers: {
                Authorization: 'Bearer '+ localStorage.token
            }
            })
            .then(function (response) {
                if (response.status === 200) {
                    setStatsArr(response.data)
                }else{
                    console.log(response.statusText)
                }

        })
    }

    //Filter Level 1
    useEffect(() => {
        setUsersFromSearch(
            users.filter((user) => {
            
                let userDepartmentsString = ""

                user.departments.map(d => {
                    userDepartmentsString += " " + d.name
                })

                let searchArr = searchText.toLowerCase().split(" ")

                let visible = true
                searchArr.map(query => {

                    if(!user.name.toLowerCase().includes(query) && !userDepartmentsString.toLowerCase().includes(query)) {
                        visible = false
                    } 
                })

                return(
                    visible
                )
            })
        )          
    }, [searchText, users]);


    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          To search multiple queries, add a space between each
        </Tooltip>
    );

    return (

        <Container className="main-page" fluid>
            <Container fluid>
                {userupdated && <AlertComponent type="primary" text="User Updated!" setShow={userUpdated}/>}
                {usercreated && <AlertComponent type="success" text="User Created!" setShow={userCreated}/>}
                {userdeactivated && <AlertComponent type="danger" text="User Deactivated!" setShow={userDeactivated}/>}
                {usererror && <AlertComponent type="danger" text="Error" setShow={userError}/>}
            </Container>
            <Row className="row-header">
                    <Col sm={8} xs={9}>
                        <Container>
                            <h2 className="main-header">Users</h2>
                        </Container>
                    </Col>
                    <Col sm={4} xs={3} className="buttons">
                        <Container>
                            {role === "Admin" &&
                                <Button className="primary-button  border-button" onClick={() => {openNewUser(true); setUserID("")}} >
                                    <FontAwesomeIcon icon={faPlusSquare}  size='1x' className='all-font-icons' />  New
                                </Button>
                            }
                        </Container>
                    </Col>
            </Row>

            <Container fluid>
                    <hr style={{backgroundColor: '#C8C8C8', width: '100%', height: '2px', marginTop: '0'}}></hr> 
            </Container>

            <Container fluid >
                <Row style={{marginBottom: "15px", paddingLeft: "15px"}}>

                    <Col lg={9} md={7}  xs={6} className="view-col">
                            <div className="card-view-button view-button" style={{fontWeight: chosenView === "card" && "550"}} onClick={() => setChosenView("card")}>
                                {" Card View"}
                            </div>
                            <div className="view-button" style={{fontWeight: chosenView === "table" && "550"}} onClick={() => setChosenView("table")}>
                                Table View
                            </div>
                    </Col>
                        
                    <Col lg={3} md={5} xs={6}>
                        
                        <FormControl variant="filled" style={{width: "100%", marginRight: '12px'}} onChange={(e) => setSearchText(e.target.value)} >
                                <InputLabel htmlFor="input-with-icon-adornment" className="search-placeholder">
                                    Search Name and Departments
                                </InputLabel>
                                <FilledInput
                                id="input-with-icon-adornment"
                                className="search-bar"
                                endAdornment={
                                    <InputAdornment position="end" style={{color: '#224166'}}>
                                        <FontAwesomeIcon icon={faSearch} className="fontWidgetIcon" size='1x' />
                                    </InputAdornment>
                                }
                                startAdornment={
                                    <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                    >
                                    <InputAdornment position="start" style={{color: '#224166'}}>
                                        <FontAwesomeIcon icon={faInfoCircle} className="fontWidgetIcon" size='1x' />
                                    </InputAdornment>
                                    </OverlayTrigger>
                                }
                                />
                        </FormControl>
                    </Col>
        
                </Row>
            </Container>

            <Container fluid>
            <Row>
                
                  {(statsArr.length > 0 && chosenView === "card") && usersFromSearch.map( (u, index) => {
                      return (
                          <Col lg={2} md={4} xs={6}>
                              <UserCard user={u} handleUserClick={handleUserClick} setUserID={setUserID} stats={statsArr[index]}/>
                          </Col>
                      )
                  })}          

                  {(statsArr.length > 0 && chosenView === "table") &&
                  <Container fluid>
                    <UsersTable 
                        data={usersFromSearch} 
                        EditModal={openNewUser} 
                        SetID={setUserID} 
                        RemoveModal={openRemoveUser} 
                        statsArr={statsArr}
                        role={role}
                    />
                  </Container>
                   }
            </Row>
            </Container>

            {newusermodal && <NewUserModal  users={users} userID={userID} newusermodal={newusermodal} openNewUser={openNewUser} userCreated={userCreated} userUpdated={userUpdated} userError={userError} callUsers={callUsers} />}
            {removeusermodal && <RemoveUserModal userID={userID} removeusermodal={removeusermodal} openRemoveUser={openRemoveUser} userDeactivated={userDeactivated} userUpdated={userUpdated} userError={userError} callUsers={callUsers}/>}

        </Container> 
        
    )
}

export default Users