import React, { useState } from 'react'
import { Modal, Form, Col, InputGroup, Button, Spinner } from 'react-bootstrap'
//import { newCar, updateCar } from '../../actions/cars'
import axios from 'axios'
import { endpoint } from '../../utils/api'
import '../../css/components/tags.css'
import SaveButton from '../../elements/SaveButton'

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeading, faShapes, faSitemap, faSave } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faHeading, faShapes, faSitemap, faSave)

const TagModal = ({openNewModal, setOpenNewModal, tagError, tagCreated, getTags, setTags, tagID, tags, tagUpdated, chosenDepartment}) => {
    const [validated, setValidated] = useState(false)
    const [loading, setLoading] = useState(false)

    let selectedTag = {}

    Object.keys(tags).map((tag) => { 
        if(tags[tag]._id === tagID){
            selectedTag = tags[tag]                  
        }
     });
    const handleSubmit = async (e, props) => {
        const form = e.currentTarget
        setLoading(true)
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            setValidated(true)
            setLoading(false)
        } else {
            e.preventDefault()

            const formData = new FormData(e.target),
                    formDataObj = Object.fromEntries(formData.entries())
            let method
            if (tagID === '') {
                method = "post"
            } else {
                method = "put"
            }
            await axios({
                method: method,
                url: `${endpoint}/tags`, 
                data: {
                    name: formDataObj.name,
                    tag_id: formDataObj.tag_id,
                    department: chosenDepartment,
                },
                headers: {
                    "Content-Type": "application/JSON",
                    Authorization: 'Bearer '+ localStorage.token,
                    DepartmentName: chosenDepartment
                  },
            })
                .then(function (response) {
                    if (response.status === 200) {
                        if (tagID === ''){
                            tagCreated(true)
                        } else {
                            tagUpdated(true)
                        } 
                        setOpenNewModal(false)
                        getTags(setTags, chosenDepartment)
                        setLoading(false)
                    } else {
                        console.log(response.statusText)  
                        console.log(response.errors)   
                        tagError(true)    
                        setOpenNewModal(false)  
                        setLoading(false)         
                    }
                })
                .catch((error) => {
                    console.log(error)
                    tagError(true)    
                    setOpenNewModal(false)
                    setLoading(false)
                })
            }
        }
    

    return (
        <Modal show={openNewModal} onHide={() => setOpenNewModal(false)}>
            <Modal.Header className="heading">
                <Modal.Title>{tagID === "" ? "New Tag" : "Edit Tag"}</Modal.Title>
            </Modal.Header>
               
            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Modal.Body>
                    <Form.Control type="hidden" name="tag_id" defaultValue={tagID !== '' ? tagID : ''}/>
                        <Form.Group as={Col}>
                            <Form.Label>Name</Form.Label>
                            <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                    <InputGroup.Text><FontAwesomeIcon icon={faHeading}  size='1x' className='all-font-icons' /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="Tag Name..." id="name" name="name" defaultValue={tagID === "" ? "" : selectedTag.name} required/>
                                <Form.Control.Feedback type="invalid">
                                    Please choose a name.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridContactName">
                            <Form.Label>Department</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FontAwesomeIcon icon={faSitemap}  size='1x' className='all-font-icons' /></InputGroup.Text>
                                </InputGroup.Prepend>
                                    <Form.Control type="text" name="department" value={chosenDepartment} defaultValue={chosenDepartment} disabled >            
                                    </Form.Control>
                            </InputGroup>
                        </Form.Group> 
                      
                </Modal.Body>

                <Modal.Footer className="footer">
                    <Button variant="secondary" onClick={() => setOpenNewModal(false)}>Close</Button>
                    <SaveButton loading={loading} />
                </Modal.Footer>
            </Form>
        </Modal>
    )

    }
export default TagModal