import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Container, Button} from 'react-bootstrap'
import { endpoint } from '../../utils/api';
import '../../css/components/tags.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPuzzlePiece, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

import { Chip } from '@mui/material';

const SectionCard = ({section, handleClicked, fileSize}) => {   

    return (
        <Card className="section" key={"card-" + section._id.toString()} style={{ width: '100%' }} onClick={() => {handleClicked(section._id)}} >
        <Row>
            <Col sm={2} xs={2} md={2} style={{padding: '0'}}>
                <Card.Body style={{marginTop: '30px'}}>
                        <span className='fa-layers fa-2x fa-fw'>
                            <FontAwesomeIcon icon={faPuzzlePiece} className="icon" />
                        </span>                           
                </Card.Body>
            </Col>
            <Col sm={10} md={9} xs={9} style={{textAlign: 'left', paddingRight: '0', paddingLeft: '10px', position: 'relative', minHeight: '140px'}} >
                <Card.Body style={{padding: '0', paddingTop: '15px'}}>
                    <Card.Title style={{marginBottom: '6px'}}>{ section.name }</Card.Title>
                    <Card.Subtitle className="tag-updated-by new-line" style={{marginBottom: '20px'}}>
                        { section.description } 
                        <div style={{marginBottom: '10px', marginTop: "5px"}}>
                            {section.tags.map((tag) => {
                                return <Chip className="chip-tags" key={section._id + tag} label={tag}/>
                            })
                            } 
                        </div>
                    </Card.Subtitle>


                    <Card.Subtitle className="tag-updated-by new-line" style={{ position: 'absolute', bottom: '0', fontSize: "14px"}}>
                        {fileSize !== undefined && (fileSize/1000000).toFixed(1) + " MB" }
                    </Card.Subtitle>

                </Card.Body>
            </Col>
           
        </Row>
    </Card>    
    )

}

export default SectionCard