import React, {useEffect} from 'react'
import { Card, Badge, Button, Row, Col } from 'react-bootstrap'
import '../../css/components/sections.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faArrowCircleUp)
const RevisionCardDeleted = ({revision, setRevisionSelected, revisionSelected, section}) => {
    let selected = false;
    selected = revision._id === revisionSelected && true

    return (
        <Card className={selected ? "deleted-revision-card-selected" : "deleted-revision-card"} onClick={() => setRevisionSelected(revision._id)} >
            <Card.Body style={{paddingTop: '15px'}}>
                <Row>
                <Col lg={12} style={{marginRight: selected && '0'}}>
                <Card.Title style={{fontSize: "14px"}}>{section.revision === revision.revision ? "Active Revision ("+revision.revision+")" :"Revision "+ revision.revision}</Card.Title>
                
                </Col>
                
                </Row>                        
            </Card.Body>
        </Card>           
    )
}

export default RevisionCardDeleted
