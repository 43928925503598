
import axios from "axios";
export const endpoint =  "https://mach-intranet.com:9000" // "http://localhost:5000" //"https://api.jptesting.co.uk:8080"

export async function getSections(setSections, department, category) {
    let response = await fetch(`${endpoint}/sections/${department}/${category}`, {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        DepartmentName: department
      },
    });
    let j = await response.json();
    setSections(j);  
}

export async function getFileSizes(department, Ids, setFileSizes)
{

    await axios({
        method: "post",
        url: `${endpoint}/sections/stats`, 
        data: {
            Ids: Ids
        } ,
        headers: {
            Authorization: 'Bearer '+ localStorage.token,
            DepartmentName: department
        }
        })
        .then(function (response) {
            if (response.status === 200) {
                setFileSizes(response.data)
            }else{
                console.log(response.statusText)
            }

    })
}

export async function getDeletedSections(setDeletedSections, department) {
    let response = await fetch(`${endpoint}/departments/${department}/sections/deleted`, {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        DepartmentName: department
      },
    });
    let j = await response.json();
    setDeletedSections(j);  
}

export async function getSection(setSection, id, department) {
    let response = await fetch(`${endpoint}/sections/section/data/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        DepartmentName: department
      },
    });
    let j = await response.json();
    setSection(j);
  
  }

export async function getTemplates(setTemplates, department, category) {
    let response = await fetch(`${endpoint}/templates/get/${department}/${category}`,{
        headers: {
            Authorization: 'Bearer '+ localStorage.token,
            DepartmentName: department
        }
    } );

    let j = await response.json();
    let templates = {}
    for (let i = 0; i < Object.keys(j).length; i++) {
        templates[j[i]._id] = j[i];
    }
    setTemplates(j);
}
export async function getTemplate(setTemplate, id, department)
{
    //load template
    let response = await fetch(`${endpoint}/templates/${department}/${id}`,{
        headers: {
            Authorization: 'Bearer '+ localStorage.token,
            DepartmentName: department
        }
    } );
    
    let j = await response.json();
    setTemplate(j);
}


export async function getCategories(setCategories, department) {
    let response = await fetch(`${endpoint}/categories/${department}`,{
        headers: {
            Authorization: 'Bearer '+ localStorage.token,
            DepartmentName: department
        }
    } );

    let j = await response.json();
    let categories = {}
    for (let i = 0; i < Object.keys(j).length; i++) {
        categories[j[i]._id] = j[i];
    }    
    setCategories(j);
}

export async function getCategoryStats(setCategoryStats, Ids) {
    await axios({
        method: "Post",
        url: `${endpoint}/categories/stats/`, 
        data: {
            Ids: Ids
        } ,
        headers: {
            Authorization: 'Bearer '+ localStorage.token
        }
        })
        .then(function (response) {
            if (response.status === 200) {
                setCategoryStats(response.data)
            }else{
                console.log(response.statusText)
            }

    })
}

export async function getTags(setTags, department) {
    let response = await fetch(`${endpoint}/tags/${department}`,{
        headers: {
            Authorization: 'Bearer '+ localStorage.token,
            DepartmentName: department
        }
    } );

    let j = await response.json();

    setTags(j);

}

export async function getModalTags(setTags, department) {
    let response = await fetch(`${endpoint}/tags/${department}`, {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        DepartmentName: department
      },
    });
    let j = await response.json();
  
    let tagNames = []
    j.map(tag => {
      tagNames.push(tag.name);
    } )

    setTags(tagNames);
  }

export async function getUsers(setUsers, activeonly)
{
    //load template
    let response = await fetch(`${endpoint}/users/all/${activeonly}`,{
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer '+ localStorage.token
        } 
    });
    
    let j = await response.json();

    setUsers(j);
}

export async function getUser(setUser)
{
    //load template
    let response = await fetch(`${endpoint}/users/me`,{
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer '+ localStorage.token
        } 
    });
    
    let j = await response.json();

    setUser(j);
}

export async function getDepartments(setDepartments)
{
    //load template
    let response = await fetch(`${endpoint}/departments/all`,{
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer '+ localStorage.token,
        
        } 
    });
    
    let j = await response.json();

    setDepartments(j);
}

export async function getDepartment(setDepartment, id)
{
    //load department
    let response = await fetch(`${endpoint}/departments/department/find/${id}`,{
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer '+ localStorage.token,
            Department: id
        } 
    });
    
    let j = await response.json();
    setDepartment(j);
}
export async function getDesignatedDepartments(setDesignated)
{
    //load template
    let response = await fetch(`${endpoint}/departments/designated`,{
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer '+ localStorage.token
        } 
    });
    
    let j = await response.json();

    setDesignated(j);
}
export async function getDepartmentPermissions(setDepartmentPermission, department)
{
    let response = await fetch(`${endpoint}/departments/check/departmentpermission/${department}`,{
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer '+ localStorage.token,
            DepartmentName: department
        } 
    });    
    let j = await response.json();
    setDepartmentPermission(j);
}
export async function getTeams(setTeams)
{
    //load template
    let response = await fetch(`${endpoint}/teams`,{
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer '+ localStorage.token
        } 
    });
    
    let j = await response.json();
    let teams = {}
    for (let i = 0; i < Object.keys(j).length; i++) {
        teams[j[i]._id] = j[i];
    }

    setTeams(teams);
}


/* REPORTS */
export async function getReport(setReport, id)
{
    //load template
    let response = await fetch(`${endpoint}/reports/${id}`,{
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer '+ localStorage.token
        } 
    });
    
    let j = await response.json();

    setReport(j);
}

export async function getReports(setReports)
{
    //load template
    let response = await fetch(`${endpoint}/reports`,{
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer '+ localStorage.token
        } 
    });
    
    let j = await response.json();

    setReports(j);
}

export async function getFeeTemplates(setFeeTemplates, department)
{
    //load department
    let response = await fetch(`${endpoint}/feetemplates/${department}`,{
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer '+ localStorage.token,
            departmentName: department
        } 
    });

    let j = await response.json();
    setFeeTemplates(j);
}


