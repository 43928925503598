import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios'
import { endpoint } from '../../utils/api'
import DeleteButton from '../../elements/DeleteButton'

const RemoveTagModal = ({ tagID, removeTag, setOpenRemoveTag, tagDeleted, tagError, getTags, setTags, chosenDepartment }) => {

    const [loading, setLoading] = useState(false)

    const deleteTag = async () => {
        setLoading(true)
        await axios({
                    method: "DELETE",
                    url: `${endpoint}/tags/${tagID}`,
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token,
                        DepartmentName: chosenDepartment
                    }
                })
                .then(function (response) {
                    if (response.status === 200) {
                        console.log("Message Sent.")                    
                        tagDeleted(true)
                        setOpenRemoveTag(false)
                        getTags(setTags, chosenDepartment)
                        setLoading(false)
                    } 
                })
                .catch((error) => {
                    tagError(error.response.data.msg)
                    setOpenRemoveTag(false)
                    setLoading(false)
                })
    }

    return (
        <Modal show={tagID !== false ? true : false} onHide={() => setOpenRemoveTag(false)}>
            <Modal.Header className="delete-header" closeButton>
                <Modal.Title>Delete Tag</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className="modal-delete-text">Are you sure you want to delete this tag?</p>
            </Modal.Body>

            <Modal.Footer className="footer">
                <Button variant="secondary" onClick={() => setOpenRemoveTag(false)}>Close</Button>
                <DeleteButton onClick={deleteTag} loading={loading} />
            </Modal.Footer>
        </Modal>
    )
}

export default RemoveTagModal