import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import DeletedSectionsCard from './DeletedSectionCard';
import AlertComponent from '../alerts/AlertComponent';
import PreviewSection from './PreviewSection';
import { getDeletedSections, getFileSizes } from '../../utils/api';
import DeleteModal from './DeleteModal';
import BinTable from './BinTable';
import RestoreSectionModal from './RestoreSectionModal';
import axios from 'axios';

import '../../css/components/sections.css'
import SearchBar from '../../elements/SearchBar';

const Bin = ({chosenDepartment, departmentPermission}) => {
    const [openDeleteSection, setOpenDeleteSection] = useState(false);
    const [openRestoreSection, setOpenRestoreSection] = useState(false);

    const [sectiondeleted, sectionDeleted] = useState(false)
    const [sectionerror, sectionError] = useState(false)
    const [sectionupdated, sectionUpdated] = useState(false);
    const [sectionID, setSectionID] = useState('');
    const [searchText, setSearchText] = useState("");
    const [chosenView, setChosenView] = useState("card")
    const [deletedSections, setDeletedSections] = useState([]);
    const [sectionsFromSearch, setSectionsFromSearch] = useState([]);
    const [fileSizes, setFileSizes] = useState([]);
    const [clickedSection, setClickedSection] = useState(null);

    let Ids = []
    deletedSections.map(s => {
        Ids.push(s._id)
    })

    useEffect(() => {
        chosenDepartment !== null && getDeletedSections(setDeletedSections, chosenDepartment)
        setClickedSection(null)
    }, [chosenDepartment])

    useEffect(() => {
        Ids.length > 0 && getFileSizes(chosenDepartment, Ids, setFileSizes)
    }, [deletedSections])

    useEffect(() => {
        chosenView === "table" && setClickedSection(null)
    }, [chosenView])
    const handleClicked = (e, section) => {
        section._id === clickedSection ? setClickedSection(null) : e.target.formAction === undefined && setClickedSection(section._id)
    }

    //Filter Level 1
    useEffect(() => {
        setSectionsFromSearch(
          deletedSections.filter((section) => {
        
            return(
                section.name.toLowerCase().includes(searchText.toLowerCase().trim()) ||
                section.description.toLowerCase().includes(searchText.toLowerCase().trim()) 
              )
          })
        )          
    }, [searchText, deletedSections]);

   
    return (
        <Container className="main-page" fluid>   
            <Container fluid>
                {sectiondeleted && <AlertComponent type="danger" text="Section Deleted!" setShow={sectionDeleted}/>}
                {sectionerror && <AlertComponent type="danger" text="Error" setShow={sectionError}/>}
                {sectionupdated && <AlertComponent type="primary" text="Section Restored!"  setShow={sectionUpdated}/>}
            </Container>

            <Row className="row-header">
                <Col sm={8}>
                    <Container>
                        <h2 className="main-header">{chosenDepartment} Bin</h2>
                    </Container>
                </Col>
            </Row>

            <Container fluid>
                <hr style={{backgroundColor: '#C8C8C8', width: '100%', height: '2px', marginTop: '0'}}></hr> 
            </Container> 
            
            <SearchBar 
                setChosenView={setChosenView} 
                chosenView={chosenView} 
                setSearchText={setSearchText}
                placeholder="Search Sections"
            />

            <Container fluid>
                <Row >
                    <Col xs={clickedSection === null ? 12 : 6}>
                        <Row>
                            {chosenView === "card" && sectionsFromSearch.map((section, index) => {
                                
                                let sectionFileSize = [];

                                fileSizes.map(f => {
                                    section._id == f.id &&  sectionFileSize.push(f.fileSize)            
                                })

                                return (
                                    <Col lg={clickedSection === null ? 3 : 6} xs={clickedSection ? 12 : 6}>
                                        <DeletedSectionsCard 
                                            section={section} 
                                            setOpenDeleteSection={setOpenDeleteSection} 
                                            setOpenRestoreSection={setOpenRestoreSection} 
                                            setSectionID={setSectionID}
                                            handleClicked={handleClicked}
                                            clickedSection={clickedSection}
                                            departmentPermission={departmentPermission}
                                            fileSize={sectionFileSize[0]}
                                        />
                                    </Col>
                                )
                            })}
                              
                        </Row>    
                    </Col>

                    {clickedSection !== null && 
                        <Col lg={6} md={6}>
                            <PreviewSection sectionID={clickedSection} chosenDepartment={chosenDepartment} />
                        </Col>                              
                    }
                </Row>
            </Container>

            {chosenView === "table" &&
                <Container fluid>
                    <BinTable 
                        data={sectionsFromSearch} 
                        EditModal={setOpenRestoreSection} 
                        SetID={setSectionID} 
                        RemoveModal={setOpenDeleteSection} 
                        fileSizeArr={fileSizes} 
                        departmentPermission={departmentPermission}
                    />
                </Container>
            }
            
            {openDeleteSection &&
                <DeleteModal 
                    sectionID={sectionID} 
                    openDeleteSection={openDeleteSection} 
                    setOpenDeleteSection={setOpenDeleteSection} 
                    sectionDeleted={sectionDeleted} 
                    sectionError={sectionError} 
                    getDeletedSections={getDeletedSections} 
                    setDeletedSections={setDeletedSections} 
                    chosenDepartment={chosenDepartment}
                    setClickedSection={setClickedSection} 
                    />
            }

            {openRestoreSection &&
                <RestoreSectionModal 
                    sectionID={sectionID} 
                    openRestoreSection={openRestoreSection} 
                    setOpenRestoreSection={setOpenRestoreSection} 
                    sectionUpdated={sectionUpdated} 
                    sectionError={sectionError} 
                    getDeletedSections={getDeletedSections} 
                    setDeletedSections={setDeletedSections} 
                    chosenDepartment={chosenDepartment}
                    setClickedSection={setClickedSection} 
                    />
            }


        </Container>
    )
}

export default Bin
