import React from 'react'
import { Card, Row, Col, Container} from 'react-bootstrap'
import { Draggable } from 'react-beautiful-dnd'
import { Chip } from '@mui/material';

import '../../css/components/template.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";

const SectionCard = ({section, index, number, draggedID}) => {
    return (
    
        <Draggable key={section._id} draggableId={section._id.toString()}  key={section._id} number index={index}>
            {provided => (
                <Card className={section._id === draggedID ? "section-card-drag" : "section-card"} key={"card-" + section._id.toString()} style={{ width: '18rem' }}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}              
            >
                <Row>
                    <Col xs={2}>
                        <Card.Body className="icon-card-body">
                        {number ?
                                <span class="dot">{index+1}</span>
                                :   
                                <span className='fa-layers fa-2x fa-fw'>
                                <FontAwesomeIcon icon={faPuzzlePiece} className="icon" />
                                </span>
                        }
                            
                        </Card.Body>

                    </Col>
                    <Col xs={10}>
                        <Card.Body>
                            <Card.Title>{ section.name }</Card.Title>
                            <Card.Subtitle className="text-muted">
                                { (section.category === undefined || section.category === null) ? "No Category" : section.category.title}
                            </Card.Subtitle>
                            {section.tags.length === 0 ? <Card.Subtitle className="text-muted" style={ {marginTop: "1px"}}>No Tags </Card.Subtitle>:
                            section.tags.map((tag) => {
                                return <Chip className="chip-tags" key={section._id + tag} label={tag}/>
                            })
                            } 
                                
                        </Card.Body>
                    </Col>
                </Row>
            </Card>    
            
            )}
        </Draggable>       
    );
}

export default SectionCard