import React, { useState } from 'react'
import { Modal, Form, Col, InputGroup, Button, Spinner } from 'react-bootstrap'
import axios from 'axios'
import { endpoint } from '../../utils/api'

//CSS
import '../../css/components/categories.css'

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeading, faShapes, faSitemap, faExclamation, faSave } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faHeading, faShapes, faSitemap)

const CategoryModal = ({openNewModal, setOpenNewModal, categoryError, categoryCreated, getCategories,setCategories, categoryID, categories, categoryUpdated, chosenDepartment}) => {
    const [validated, setValidated] = useState(false)
    const [loading, setLoading] = useState(false)
    const [slashAlert, setSlashAlert] = useState(false)

    let selectedCategory = {}
    Object.keys(categories).map((cat) => { 
        if(categories[cat]._id === categoryID){
            selectedCategory = categories[cat]          
        }
     });

    const handleTitleChange = (title) => {
        title.includes("/") ? setSlashAlert(true) : setSlashAlert(false)
    } 

    const handleSubmit = async (e, props) => {
        const form = e.currentTarget        
        const formData = new FormData(e.target),
        formDataObj = Object.fromEntries(formData.entries())

        setLoading(true)
        if (form.checkValidity() === false || slashAlert) {
            e.preventDefault()
            e.stopPropagation()
            setValidated(true)
            setLoading(false)
        } else {
            e.preventDefault()


            let method
            if (categoryID === '') {
                method = "post"
            } else {
                method = "put"
            }
            await axios({
                method: method,
                url: `${endpoint}/categories`, 
                data: {
                    title: formDataObj.title,
                    category_id: formDataObj.category_id,
                    department: chosenDepartment
                },
                headers: {
                    "Content-Type": "application/JSON",
                    Authorization: 'Bearer '+ localStorage.token,
                    DepartmentName: chosenDepartment
                    },
            })
                .then(function (response) {
                    if (response.status === 200) {
                        if (categoryID === ''){
                            categoryCreated(true)
                        } else {
                            categoryUpdated(true)
                        } 
                        setOpenNewModal(false)
                        getCategories(setCategories, chosenDepartment)
                        setLoading(false)
                    } else {
                        categoryError("Error")    
                        setOpenNewModal(false)  
                        setLoading(false)         
                    }
                })
                .catch((error) => {
                    categoryError(error.response.data)    
                    setOpenNewModal(false)
                    setLoading(false)
                })
            }
        }

    return (
        <Modal show={openNewModal} onHide={() => setOpenNewModal(false)}>
            <Modal.Header className="heading">
                <Modal.Title>{categoryID === "" ? "New Category" : "Edit Category"}</Modal.Title>
            </Modal.Header>
               
            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Modal.Body>
                    <Form.Control type="hidden" name="category_id" defaultValue={categoryID !== '' ? categoryID : ''}/>
                        <Form.Group as={Col}>
                            <Form.Label>Title</Form.Label>
                            <InputGroup className="mb-2" onChange={(e) => {handleTitleChange(e.target.value)}}>
                            <InputGroup.Prepend>
                                    <InputGroup.Text><FontAwesomeIcon icon={faHeading}  size='1x' className='all-font-icons' /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="Category Title..." id="title" name="title" defaultValue={categoryID === "" ? "" : selectedCategory.title} required/>
                                <Form.Control.Feedback type="invalid">
                                    Please choose a title.
                                </Form.Control.Feedback>
                            </InputGroup>
                            {slashAlert && 
                                <h5 className="no-category">            
                                    <FontAwesomeIcon 
                                    icon={faExclamation}  
                                    size='1x' 
                                    className='all-font-icons no-tags-icon' 
                                    />
                                        Category names containing "/" are prohibited
                                </h5>  
                            }                        
                        </Form.Group>

                        
                        <Form.Group as={Col} controlId="formGridContactName">
                            <Form.Label>Department</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FontAwesomeIcon icon={faSitemap}  size='1x' className='all-font-icons' /></InputGroup.Text>
                                </InputGroup.Prepend>
                                    <Form.Control type="text" name="department" value={chosenDepartment} defaultValue={chosenDepartment} disabled >                     
                                    </Form.Control>
                            </InputGroup>
                        </Form.Group> 

                      
                      
                </Modal.Body>

                <Modal.Footer className="footer">
                    <Button variant="secondary" onClick={() => setOpenNewModal(false)}>Close</Button>
                    <Button className="save-button primary-button border-button" type="submit" disabled={loading || slashAlert }>
                    {loading ? 
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        variant="light"     
                        />
                        :
                        <>
                        <FontAwesomeIcon icon={faSave}  size='1x' className='all-font-icons' />  Save
                        </>
                    }          
                </Button>  
                </Modal.Footer>
            </Form>
        </Modal>
    )

    }
export default CategoryModal