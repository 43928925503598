import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Button  } from 'react-bootstrap'
import { endpoint } from '../../utils/api';
import '../../css/components/report.css'

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

const ReportWidget = ({report, setOpenNewModal, setOpenRemoveReport, setReportID, role, downloadFile, fileSize }) => {

  return (
    <Container className="report-widget">

        <Row>
            <Col lg={12} style={{paddingLeft: '0'}}>
                <h5 style={{textAlign: 'left'}}>{report.name}</h5>
            </Col>
        </Row>

        <Row style={{marginBottom: '4px'}}>
            <Col md={9} style={{paddingLeft: '0'}}>
            <h5 style={{textAlign: 'left', color: '#504F4F', fontSize: '15px',  marginTop:'5px'}}>{report.description}</h5> 
            </Col>
            <Col md={3} style={{textAlign: 'right', paddingRight:'0'}}>
                <Container style={{marginTop:'-5px', padding: '0', marginLeft: '0'}}>
                 {role === "Admin" && 
                     <>
                        <Button className="btn btn-light btn-sm" style={{height: '35px', width: '38px', marginRight:'2px'}} onClick={() => {downloadFile(report._id)}}>
                            <FontAwesomeIcon icon={faFileDownload}  size='1x' className='all-font-icons' style={{fontSize: '14px', marginBottom: '0px'}}/>
                        </Button>

                        <Button className="primary-button border-button" style={{height: '35px', width: '38px' , marginRight:'2px'}} onClick={() => { setOpenNewModal(true); setReportID(report._id)}}>
                            <FontAwesomeIcon icon={faPen}  size='1x' className='all-font-icons' style={{fontSize: '13px', marginBottom: '2px'}}/> 
                        </Button>

                        <Button variant="danger" style={{height: '35px', width: '38px' }} onClick={() => {setOpenRemoveReport(true); setReportID(report._id) }}>
                            <FontAwesomeIcon icon={faTrash}  size='1x' className='all-font-icons' style={{fontSize: '13px', marginBottom: '2px'}}/> 
                        </Button>
                    </>
                }
                </Container>
            </Col>
    
        </Row>
       
        <Row>
            <iframe src={"https://view.officeapps.live.com/op/embed.aspx?src=" + "http://machreporter.co.uk/reports/download/" + report._id}  width="100%" height="500" frameborder='1'>This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe>
        </Row>

        <Row style={{paddingTop: '8px', paddingBottom: '2px'}} >
            <Col style={{textAlign: "left", paddingLeft:"0"}}>
                <h5 style={{fontSize: '14px', color: '#7C7C7C'}}>
                    {"Last updated by " + report.last_updated_by.name}
                </h5>
            </Col>
            <Col style={{textAlign: "right", paddingRight: "0"}}>
                <h5 style={{fontSize: '14px', color: '#7C7C7C'}}>
                    {"File Size: " + (fileSize/1000000).toFixed(1) + " MB"}
                </h5>
            </Col>
        </Row>

    </Container>
  )
}

export default ReportWidget
