import React, { useState, useCallback, useEffect, useLayoutEffect } from "react";

//import "@yaireo/tagify/dist/tagify.css" // Tagify CSS

import Tags from "@yaireo/tagify/dist/react.tagify";// React-wrapper file

const SectionTags = ({ setSelectedTags, defaultValue, whitelist} ) => {
    
    const [tagifyProps, setTagifyProps] = useState({})

    let tags = [];
    whitelist.map(tag => {
        tags.push(tag.name);
    })
    // Tagify settings object
    const baseTagifySettings = {
        maxTags: 5,

        dropdown: {
            maxItems: 15,           // <- mixumum allowed rendered suggestions
            classname: "tags-look", // <- custom classname for this dropdown, so it could be targeted
            enabled: 0,             // <- show suggestions on focus
            closeOnSelect: false
        }
    }
    
    // on tag add/edit/remove
    const onChange = useCallback((e) => {
        let newTags = []
        e.detail.tagify.getCleanValue().map(tag => {
            newTags.push(tag.value)
        })
        setSelectedTags(newTags);    
    }, [])

    //import css from cdn as it wasnt working with import
    return (      
        <>
            <link href="https://unpkg.com/@yaireo/tagify/dist/tagify.css" rel="stylesheet" type="text/css" />
            <Tags
            className="tag-selector tagify"                        
            settings={baseTagifySettings}  // tagify settings object
            placeholder= "Select Tags"
            defaultValue={defaultValue}
            {...tagifyProps}   // dynamic props such as "loading", "showDropdown:'abc'", "value"
            onChange={onChange}
            whitelist={tags}            
            />
        </>
    )
}

export default SectionTags