import React, { useEffect, useState, useRef } from 'react'
import {Overlay, Popover, Row, Col} from 'react-bootstrap'
import '../../css/components/logout.css'
import { getUser } from '../../utils/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserShield, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import { Avatar } from '@mui/material';

library.add(faUser, faUserShield)
const UserIcon = ({ dispatch }) => {
  const [user, setUser] = useState([]);
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null); 


  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {

        if (event.target.innerText != user?.name?.split(" ").map((n)=>n[0]).join("") || event.target.className.includes("col-lg-2")) {
          setShow(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, user]);
  }
  useEffect(() => {
    getUser(setUser);
  },[])
  
  const handleClick = (e) => {

    if(e.target.className === "user-profile" || e.target.innerHTML === user?.name?.split(" ").map((n)=>n[0]).join("")) {
      setTarget(e.target);
      setShow(!show);
    }
  
  };

  const logOut = () => {
    localStorage.removeItem("token")
    window.location.reload()
  }
  
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  
  return (
      <div className="user-profile" onClick={(e) => handleClick(e)} >
        <p style={{marginTop: '4px'}}>{user.length !== 0 && user.name.split(" ").map((n)=>n[0]).join("")}</p>
        <Overlay
      placement="bottom"
      show={show}
      target={target}
      transition={true}
      >
      <Popover className="user-popover" ref={wrapperRef}>
        <Row style={{padding: '8px'}}>
        <Col sm={3}>
        <Avatar className={user.role === "Admin" ? "user-role-icon-admin" : "user-role-icon-user"} >
            <FontAwesomeIcon icon={user.role === "Admin" ? faUserShield : faUser}  
            size='1x' 
            className='all-font-icons department-chip-icon' 
            />
        </Avatar>
        </Col>
        <Col sm={9} style={{marginTop: '-2px' }}>
            <Row style={{fontSize: '16px', fontWeight: '550'}}>
              {user?.name}      
          
            </Row>
            <Row style={{fontSize: '12px', fontWeight: '450'}}>
            {user?.email}
            </Row>
         </Col>
         </Row>
         <hr style={{backgroundColor: '#4990D9', height: '0.5px', padding: '0', margin: '0'}}></hr>
         <Row className="delete-row" onClick={() => logOut()}>
           <Col sm={2}>
           <FontAwesomeIcon icon={faSignOutAlt}
            size='1x' 
            style={{fontSize: '18px', color: '#4B4B4B'}} 
          />
           </Col>
            <Col sm={10} style={{fontWeight: '550', fontSize: '15px', color: '#444141', marginTop: '-3px', cursor: 'pointer'}}>
            Logout
            </Col>
        
         </Row>
      </Popover>
      </Overlay>
      </div>

  

    )
}

export default UserIcon
