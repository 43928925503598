import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios'
import { endpoint } from '../../utils/api'
import { useHistory } from 'react-router-dom'
import DeleteButton from '../../elements/DeleteButton'

const RemoveTemplateModal = ({ templateID, removetemplatesection, openRemoveTemplate, setTemplateDeleted, setTemplateError, getTemplates, setTemplates, department, chosenCategory }) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false)

    const removeTemplate = async () => {
        setLoading(true)
        await axios({
                    method: "DELETE",
                    url: `${endpoint}/templates/${templateID}`,
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token,
                        DepartmentName: department
                    }
                })
                .then(function (response) {
                    if (response.status === 200) { 
                        setLoading(false)                
                        setTemplateDeleted(true)
                        openRemoveTemplate(false)
                        if (getTemplates === undefined) {
                            history.push(`/templates`);
                            window.location.reload();
                        } else {
                            getTemplates(setTemplates, department, chosenCategory)
                        }   
                        
                    } 
                })
                .catch((error) => {
                    setTemplateError(error.response.data.msg)
                    openRemoveTemplate(false)
                    setLoading(false)
                })
    }

    return (
        <Modal show={removetemplatesection !== false ? true : false} onHide={() => openRemoveTemplate(false)}>
            <Modal.Header className="delete-header" closeButton>
                <Modal.Title>Delete template</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className='modal-delete-text'>Are you sure you want to delete this template?</p>
            </Modal.Body>

            <Modal.Footer className="footer">
                <Button variant="secondary" onClick={() => openRemoveTemplate(false)}>Close</Button>
                <DeleteButton onClick={removeTemplate} loading={loading} />
            </Modal.Footer>
        </Modal>
    )
}

export default RemoveTemplateModal