import React, { useEffect, useState } from 'react'
import { Modal, Form, Row, Col, InputGroup, Button, Spinner } from 'react-bootstrap'
import axios from 'axios'
import { endpoint } from '../../../utils/api';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import SaveButton from '../../../elements/SaveButton';
//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature, faHistory, faUsers, faUserShield, faSave, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

//CSS
import '../../../css/icons/stylingIcons.css'
import '../../../css/components/departments.css'

library.add(faFileSignature, faHistory, faUserShield, faSave)
const animatedComponents = makeAnimated();

const NewDepartmentModal = ({users, departmentID, newdepartment, openNewDepartment, departmentCreated, departmentUpdated, departmentError, callDepartments }) => {

    const [members, setMembers] = useState([])
    const [admins, setAdmins] = useState([])
    const [availableUsers , setAvailableUsers] = useState([]);
    const [duplicate, setDuplicate] = useState("");
    const [loading, setLoading] = useState(false);
    const [slashAlert, setSlashAlert] = useState(false);
    const [validated, setValidated] = useState(false)
    
    useEffect(() => {
        let arr = [];
        Object.keys(users).map((user) => {
            let dudObj = {
                value:"",
                label:""
            }
            dudObj.value = users[user].name
            dudObj.label = users[user].name
            arr.push(dudObj)
        }) 
        setAvailableUsers(arr);
    }, [])

    useEffect(() => {
        setDuplicate("");
        admins.map(a => {
            if (members.includes(a)){
                setDuplicate(a);
            } 
        })
    }, [members , admins])

    const updateMembers = (e) => {

        setMembers([])
            e.map(member => {
                setMembers((prevMembers) => {
                    return [...prevMembers, member.label]
                }) 

            })
    }

    const updateAdmins = (e) => {

        setAdmins([]);
            e.map(admin => {
                setAdmins((prevAdmins) => {
                    return [...prevAdmins, admin.label]
                })   

            })        
    }      
    
    const handleTitleChange = (title) => {
        title.includes("/") ? setSlashAlert(true) : setSlashAlert(false)
    }       

    const handleSubmit = async (e, props) => {
        const form = e.currentTarget
        setLoading(true)
        if (form.checkValidity() === false || slashAlert || duplicate !== "") {
            e.preventDefault()
            e.stopPropagation()
            setValidated(true)
            setLoading(false)
        } else {
            e.preventDefault()

            const formData = new FormData(e.target),
            formDataObj = Object.fromEntries(formData.entries())
            await axios({
                method: "POST",
                url: `${endpoint}/departments`, 
                data: {
                    name: formDataObj.name,
                    members: members,
                    admins: admins,
                    is_deleted: formDataObj.is_deleted
                } ,
                headers: {
                    Authorization: 'Bearer '+ localStorage.token
                }
            })
                .then(function (response) {
                    if (response.status === 200) { 
                        setLoading(false)              
                        departmentCreated(true)                  
                        openNewDepartment(false)
                        callDepartments();
                    } else {
                        setLoading(false)
                        console.log(response.statusText)  
                        console.log(response.errors)   
                        departmentError("Error")   
                        openNewDepartment(false)            
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    departmentError(error.response.data)   
                    openNewDepartment(false)
                })

        }
    }
    return (
        <Modal show={newdepartment} onHide={() => openNewDepartment(false)}>
            <Modal.Header className="heading" closeButton>
                <Modal.Title>Add New Department</Modal.Title>
            </Modal.Header>

            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Modal.Body>

                <Form.Control type="hidden" name="department_id" defaultValue=""/>

                        <Form.Group as={Col} controlId="formGridDate">
                            <Form.Label>Name</Form.Label>
                            <InputGroup className="mb-2" onChange={(e) => {handleTitleChange(e.target.value)}}>
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FontAwesomeIcon icon={faFileSignature}  size='1x' className='all-font-icons' /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="Name..." name="name"  required/>
                                <Form.Control.Feedback type="invalid">
                                    Please choose a name.
                                </Form.Control.Feedback>
                            </InputGroup>
                            {slashAlert && 
                                <h5 className="no-category">            
                                    <FontAwesomeIcon 
                                    icon={faExclamation}  
                                    size='1x' 
                                    className='all-font-icons no-tags-icon' 
                                    />
                                        Category names containing "/" are prohibited
                                </h5>  
                            }
                        </Form.Group>  

                        <Form.Group as={Col} controlId="formGridDate">
                            <Form.Label>Admins</Form.Label>
                            <InputGroup className="mb-2" >
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FontAwesomeIcon icon={faUserShield}  size='1x' className='all-font-icons' /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <div className="reactSelect" style={{width: "391px"}} >
                                    <Select
                                        name="admins"
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        defaultValue=""
                                        isMulti
                                        options={availableUsers}
                                        onChange={(e) => updateAdmins(e)}
                                    />
                                </div>    
                            </InputGroup>
                        </Form.Group>                   
 

                        <Form.Group as={Col} controlId="formGridDate">
                            <Form.Label>Members</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FontAwesomeIcon icon={faUsers}  size='1x' className='all-font-icons' /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <div className="reactSelect" style={{width: "391px"}} >
                                    <Select
                                        name="members"
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        defaultValue=""
                                        isMulti
                                        options={availableUsers}
                                        onChange={(e) => updateMembers(e)}
                                    />
                                </div>    
                            </InputGroup>
                            {duplicate !== "" && <p className="duplicate-warning"> {duplicate} cannot be an admin and a member.</p>}    
                        </Form.Group>                    

                </Modal.Body>

                <Modal.Footer className="footer">
                    <Button variant="secondary" onClick={() => openNewDepartment(false)}>Close</Button>
                    <Button className="save-button primary-button border-button" type="submit" disabled={loading || slashAlert || duplicate !== ""}>
                        {loading ? 
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="light"     
                            />
                            :
                            <>
                            <FontAwesomeIcon icon={faSave}  size='1x' className='all-font-icons' />  Save
                            </>
                        }          
                    </Button> 
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default NewDepartmentModal