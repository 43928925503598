import {useState, useEffect} from 'react'
import { endpoint } from './api'
import axios from 'axios'

export const CheckUserRole = () => {
    const [role, setRole] = useState()
   
    useEffect(() => {
        if (localStorage.token !== undefined) {
        axios.get(`${endpoint}/checkUser` ,{

            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer '+ localStorage.token
            }
            })
            .then(function (response) {
                if (response.status === 200) {
                    setRole(response.data.role)
                } else {
                    console.log(response.statusText)  
                    console.log(response.errors)        
                }
            })
            .catch((error) => {
                console.log(error)
            }) 
        }
    }, [])
   

    return {
        role
    }
}

export const checkUserToken = () => {
    if (localStorage.token !== undefined) {
        axios.get(`${endpoint}/checkUser/token` ,{

            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer '+ localStorage.token
            }
            })
            .then(function (response) {
                if (response.status === 200) {
                    console.log(response.data);
                    if (response.data === false) {
                        localStorage.token = null
                        window.location.reload();
                    } 
                    
                } else {
                    console.log(response.statusText)  
                    console.log(response.errors)        
                }
            })
            .catch((error) => {
                console.log(error)
            }) 
        }
}

