import React, { useState, useEffect } from 'react'
import './App.css';
import TopNavbar from './utils/TopNavbar';
import {BrowserRouter as Router} from "react-router-dom";
import Routes from './Routes'
import Login from './components/auth/Login'
import useToken from './utils/useToken';
import { CheckUserRole, checkUserToken } from './utils/checkUser';
import LeftSidebar from './elements/LeftSidebar';
import { getDepartmentPermissions } from './utils/api';

//Global Css
import '../src/css/icons/material-design-icons.css'
import '../src/css/main.css'

function App() {
  const {role} = CheckUserRole();
  const {token, setToken } = useToken();

  const [departmentPermission, setDepartmentPermission] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [chosenDepartment, setChosenDepartment] = useState(null)
  const [lastSelected, setLastSelected] = useState(null)

  useEffect(() => {
    setLastSelected(window.location.pathname.substring(1))
    chosenDepartment !== null && getDepartmentPermissions(setDepartmentPermission, chosenDepartment);
  },[chosenDepartment])

  return (
    <Router>
    <div>
   
    {token  === null ? <Login setToken={setToken} />: (
    <div className="App">      
 

    <div>
      
    <LeftSidebar setToggled={setToggled} toggled={toggled} collapsed={collapsed} setLastSelected={setLastSelected} lastSelected={lastSelected}/>
    {toggled && <div className="sidebar-toggled-hide-navbar"></div>}
    <TopNavbar setToggled={setToggled} toggled={toggled} setCollapsed={setCollapsed} collapsed={collapsed} setChosenDepartment={setChosenDepartment} chosenDepartment={chosenDepartment}/>
      
      <div className="col main-content" style={{paddingLeft: collapsed && "100px"}}  onClick={() => setToggled(false)} > 
      {toggled && <div className="sidebar-toggled-main-content"></div>}
     
        <Routes chosenDepartment={chosenDepartment} role={role} departmentPermission={departmentPermission} />
      </div>
    </div>
    
  </div>)}
  </div>

  </Router>

  );
}

export default App;
