import React, { useState, useEffect } from "react";
import { InputGroup, Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import { endpoint, getSection, getModalTags } from "../../utils/api";
import SectionTags from "./SectionTags";
import SaveButton from "../../elements/SaveButton";
import '../../css/components/sections.css'
import '../../css/icons/stylingIcons.css'

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faExclamation)
const SectionModal = ({ openNewModal, setOpenNewModal, sectionError, setSectionCreated, setSectionUpdated, getSections, setSections, getCurrentSection, setCurrentSection, sectionID, sections, categories, chosenDepartment, chosenCategory }) => {
  const [validated, setValidated] = useState(false);
  const [selectedTags, setSelectedTags] = useState([])
  const [defaultTags, setDefaultTags] = useState([]);
  const [section, setSection] = useState([]);
  const [chosenModalCategory, setChosenModalCategory] = useState("All");
  const [tagsAlert, setTagsAlert] = useState(false);
  const [loading, setLoading] = useState(false)

  if(chosenCategory === undefined) {
    chosenCategory = "All"
  }
  let sectiontags= []
  Object.keys(sections).map((section) => { 
    if(sections[section]._id === sectionID){
      sectiontags.push(sections[section].tags);
    }
  });

  useEffect(() => {
    getModalTags(setDefaultTags, chosenDepartment);
    getSection(setSection, sectionID, chosenDepartment);
  }, [])

  useEffect(() => {
    setSelectedTags(section.tags);  
  },[section]);

  useEffect(() => {
    if ((section.category !== undefined && section.category !== null)) {
      setChosenModalCategory(section.category.title);
    }
    
  }, [section])

  useEffect(() => {
    if (selectedTags !== undefined && selectedTags.length > 0 ){
      setTagsAlert(false)
    } 
  }, [selectedTags])


  const handleSubmit = async (e, props) => {
    const form = e.currentTarget;
    setLoading(true)
    console.log(form.checkValidity());

    if ((form.checkValidity() === false) || (selectedTags === undefined || selectedTags.length === 0)) {
      setTagsAlert(true);
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      setLoading(false)
    } else {
      e.preventDefault();
      // const formDataObj = new FormData();

      const name = document.getElementById("name");
      const description = document.getElementById("description");
      const department = document.getElementById("department");
      const category = document.getElementById("category")

      let method
      if (sectionID === ''){
        method = "post";
      } else {
        method = "put";
      }  

       await axios({
                method: method,
                url: `${endpoint}/sections`, 
                data: {
                    name: name.value,
                    description: description.value,
                    section_id: sectionID,
                    tags: selectedTags,
                    department: department.value,
                    category: category.value
                } ,
                headers: {
                    Authorization: 'Bearer '+ localStorage.token,
                    DepartmentName: chosenDepartment
                }
            })
        .then(function (response) {
          if (response.status === 200) {
            console.log("Message Sent.");
            if (sectionID === ""){
              setSectionCreated(true);
            } else {
              setSectionUpdated(true)
            }            
            setOpenNewModal(false);
            setLoading(false)
            getSections !== undefined && getSections(setSections, chosenDepartment, chosenCategory);
            getCurrentSection !== undefined && getCurrentSection(setCurrentSection, sectionID, chosenDepartment);
          } else {
            console.log(response.statusText);
            console.log(response.errors);
            sectionError(true);
            setOpenNewModal(false);
            setLoading(false)
          }
        })
        .catch((error) => {
          console.log(error);
          sectionError(true);
          setOpenNewModal(false);
          setLoading(false)
        });
    }
  };


  return (
    <Modal backdropClassName="backdrop" show={openNewModal} onHide={() => setOpenNewModal(false)}>
      <Modal.Header className="heading">
        <Modal.Title >
          {sectionID !== "" ? "Edit Section" : "New Section"}
        </Modal.Title>
      </Modal.Header>

      <Form onSubmit={handleSubmit} noValidate validated={validated} defaultValue={sectionID !== '' ? sectionID : ''}>
        <Modal.Body>
          <Form.Label className="bolder">Name</Form.Label>
          <InputGroup className="mb-2">
            <Form.Control type="text" placeholder="Section Name..." id="name" name="name" defaultValue={sectionID !== '' ? section.name : ''} required />
            <Form.Control.Feedback type="invalid">Please choose a name.</Form.Control.Feedback>
          </InputGroup>

          <Form.Label className="bolder">Description</Form.Label>
          <InputGroup className="mb-2">
            <Form.Control
              type="text"
              placeholder="Section description..."
              id="description"
              name="description"
              defaultValue={sectionID !== '' ? section.description : ''}
              required
            />
            <Form.Control.Feedback type="invalid">Please choose a description.</Form.Control.Feedback>
          </InputGroup>
        
          <Form.Label className="bolder">Tags</Form.Label>
          <SectionTags setSelectedTags={setSelectedTags} whitelist={defaultTags} defaultValue={sectionID!== "" ? sectiontags : ''}/>
          {tagsAlert && 
          <h5 className="no-category">            
          <FontAwesomeIcon 
            icon={faExclamation}  
            size='1x' 
            className='all-font-icons no-tags-icon' 
            />
                You must select Tags!
            </h5>  
          }

          <Form.Label className="bolder">Category</Form.Label>
          <InputGroup className="mb-2">
                  <Form.Control as="select" name="category" id="category" onChange={(e) => setChosenModalCategory(e.target.value)}>
                  {(section.category !== undefined || section.category !== null) && chosenModalCategory === "All" && <option >Not Selected</option>}
                      {categories.map((c) => {
                          if(section.category !== undefined){ 
                              return <option selected={section.category.title === c.title} value={c.name} >{c.title} </option>
                          } else if (chosenCategory !== "All"){
                            return <option selected={chosenCategory === c.title} value={c.name} >{c.title} </option>
                          } else {
                              return <option value={c.title}>{c.title}</option>
                          }
                      })}                             
                  </Form.Control>
          </InputGroup>
          {(sectionID !== "" && section.category === undefined && chosenModalCategory == "All") && 
          <h5 className="no-category">This section has no saved category, please select one.</h5>  
          }

          <Form.Label className="bolder">Department</Form.Label>
          <InputGroup className="mb-2">
            <Form.Control type="text" placeholder="Department Name..." id="department" name="department" defaultValue={chosenDepartment} disabled />
          </InputGroup>
        </Modal.Body>

        <Modal.Footer className="footer">  
          <Button variant="secondary" onClick={() => setOpenNewModal(false)}>
            Close
          </Button>
          <SaveButton loading={loading} />         
             
        </Modal.Footer>
        
      </Form>

    </Modal>
    
  );
};

export default SectionModal;
