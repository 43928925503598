import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios'
import { endpoint } from '../../utils/api'

const ChangeRevisionModal = ({ sectionID, revisionID, changerevisionmodal, openChangeRevision, sectionUpdated, sectionError, getSection, setSection, department }) => {

    const changeRevision = async () => {
        await axios({
                    method: "PUT",
                    url: `${endpoint}/sections/changerevision`,
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token,
                        DepartmentName: department
                    },
                    data: {
                        section_id: sectionID,
                        revision_id: revisionID,
                    }
                })
                .then(function (response) {
                    if (response.status === 200) {                   
                        sectionUpdated(true)
                        openChangeRevision(false)
                        getSection(setSection, sectionID, department)
                    } 
                })
                .catch((error) => {
                    console.log(error)
                    sectionError(error)
                    openChangeRevision(false)
                })
    }

    return (
        <Modal show={changerevisionmodal !== false ? true : false} onHide={() => openChangeRevision(false)}>
            <Modal.Header className="delete-header" closeButton>
                <Modal.Title >Change Revision</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className='modal-delete-text'>Are you sure you want to change the revision?</p>
            </Modal.Body>

            <Modal.Footer className="footer">
                <Button variant="secondary" onClick={() => openChangeRevision(false)}>Close</Button>
                <Button className="primary-button border-button" onClick={() => changeRevision()}><i className="material-icons">edit</i>&nbsp;Change</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ChangeRevisionModal