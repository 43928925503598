import React from "react";
import { Container } from 'react-bootstrap'

const UnAuthUsersTable = () => {
    return (
        <Container id="main-container">
            <h1>Unauthorised Access</h1>
            <h3>Please Contact Your Local Admin</h3>
            <a href="/">Home</a>
        </Container>
    )
}

export default UnAuthUsersTable