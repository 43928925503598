import { Button, Spinner } from "react-bootstrap"

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from "@fortawesome/free-solid-svg-icons";

const SaveButton = ({loading}) => {

    return (
        <Button className="save-button primary-button border-button" type="submit" disabled={loading}>
            {loading ? 
                <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="light"     
                />
                :
                <>
                <FontAwesomeIcon icon={faSave}  size='1x' className='all-font-icons' />  Save
                </>
            }          
        </Button>  
    )
}

export default SaveButton