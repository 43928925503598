import { useState } from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../components/assets/images/MA Logo 2 - no text.png';
import './custom.scss'
import '../css/elements/sidebar.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faSitemap, faShapes, faPuzzlePiece, faUsers, faLayerGroup, faTags, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import { CheckUserRole } from '../utils/checkUser';


library.add(faFileDownload, faSitemap, faShapes, faPuzzlePiece, faUsers, faLayerGroup, faTags, faTrashAlt)

const LeftSidebar = ({setToggled, toggled, collapsed, setLastSelected, lastSelected }) => {
    const {role} = CheckUserRole();
    const [hovered, setHovered] = useState(false);

    const items = [
        {
            title: "  Files",
            icon: faFileDownload,
            link: "files"
        },
        {
            title: "Departments",
            icon: faSitemap,
            link: "departments"
        },
        {
            title: " Categories",
            icon: faShapes,
            link: "categories"
        },
        {
            title: "Tags",
            icon: faTags,
            link: "tags"
        },
        {
            title: " Templates",
            icon: faLayerGroup,
            link: "templates"
        },
        {
            title: " Sections",
            icon: faPuzzlePiece,
            link: "sections"
        },
        {
            title: "  Bin",
            icon: faTrashAlt,
            link: "bin"
        },
        {
            title: "Users",
            icon: faUsers,
            link: "users"
        },
    ]

    return (
        <ProSidebar className="sidebar" breakPoint="lg" id="#sidebar" toggled={toggled} onClick={() => setToggled(false)} collapsed={collapsed} collapsedWidth="75px" width="230px">  
            <SidebarHeader className= "nav-brand">
                <img
                    alt=""
                    src={Logo}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    />{' '}
               
            </SidebarHeader>
            <SidebarContent className="all-sidebar-components" onClick={(e) => setLastSelected(e.target.href !== undefined &&e.target.href.substring(e.target.href.lastIndexOf("/") + 1))}>
                    <Menu iconShape="square">

                    <div className='menu-header' style={{visibility: collapsed && 'hidden'}}>
                    Global
                    </div>
                    <MenuItem className={lastSelected === items[0].link ? "side-nav-item item-selected" : hovered === items[0].link ? "side-nav-item item-hovered" : "side-nav-item" } style={{marginLeft: '3px'}} onMouseEnter={() => setHovered(items[0].link)} onMouseLeave={() => setHovered(false)}>
                        <FontAwesomeIcon icon={items[0].icon}  size='1x' className={(hovered === items[0].link || lastSelected === items[0].link) ? 'icon-hover icon-classes' : 'icon-classes'} style={{fontSize: '17px'}} />
                        {items[0].title}
                        <Link to={"/"+items[0].link} />
                    </MenuItem>

                    <div className="nav-header menu-header" style={{visibility: collapsed && 'hidden'}}>
                        Departmental
                    </div>

                    {items.map((item, i) => {
                        if( i < 7 && i > 1) {
                            return (
                                <MenuItem className={lastSelected === item.link ? "side-nav-item item-selected" : hovered === item.link ? "side-nav-item item-hovered" : "side-nav-item" } onMouseEnter={() => setHovered(item.link)} onMouseLeave={() => setHovered(false)}>
                                    <FontAwesomeIcon icon={item.icon}  size='1x' className={(lastSelected === item.link || hovered === item.link) ? 'icon-hover icon-classes' : 'icon-classes'} /> 
                                    {item.title}
                                    <Link to={"/"+item.link} />
                                </MenuItem>
                            )
                        }
                    })}
                   
                        <div className="nav-header menu-header" style={{visibility: collapsed && 'hidden'}}>
                            Admin
                        </div>
                        <MenuItem className={lastSelected === items[1].link ? "side-nav-item item-selected" : hovered === items[1].link ? "side-nav-item item-hovered" : "side-nav-item" } onMouseEnter={() => setHovered(items[1].link)} onMouseLeave={() => setHovered(false)}>                       
                            <FontAwesomeIcon icon={items[1].icon}  size='1x' className={(lastSelected === items[1].link || hovered === items[1].link ) ? 'icon-hover icon-classes' : 'icon-classes'} />
                                {items[1].title}
                            <Link to={"/" + items[1].link} />
                        </MenuItem>

                        <MenuItem className={lastSelected === items[7].link ? "side-nav-item item-selected" : hovered === items[7].link ? "side-nav-item item-hovered" : "side-nav-item" } onMouseEnter={() => setHovered(items[7].link)} onMouseLeave={() => setHovered(false)}>                       
                            <FontAwesomeIcon icon={items[7].icon}  size='1x' className={(lastSelected === items[7].link || hovered === items[7].link ) ? 'icon-hover icon-classes' : 'icon-classes'} />
                                {items[7].title}
                            <Link to={"/" + items[7].link} />
                        </MenuItem>


                </Menu>
            </SidebarContent>
                 
             {/* <SidebarFooter className="side-footer">
                <hr style={{backgroundColor: '#C8C8C8', height: '0.5px', padding: '0', margin: '0'}}></hr>
                <a  onClick={() => setToggled(false)}>Close</a>
            </SidebarFooter> */}

        </ProSidebar>
    )
}

export default LeftSidebar
