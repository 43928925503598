import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import DepartmentWidget from './DepartmentWidget'
import AlertComponent from '../alerts/AlertComponent';
import NewDepartmentModal from './Modals/NewDepartmentModal';
import EditDepartmentModal from './Modals/EditDepartmentModal';
import RemoveDepartmentModal from './Modals/RemoveDepartmentModal';
import { CheckUserRole, checkUserToken } from '../../utils/checkUser';
import {FormControl, InputLabel, InputAdornment, FilledInput } from '@mui/material'
import { getDepartments, endpoint, getUsers } from '../../utils/api';
import axios from 'axios';
import DepartmentsTable from './DepartmentsTable';
import '../../css/components/admin.css'
import '../../css/components/departments.css'
//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMinus, faEye, faUsers, faUserShield, faUserAltSlash, faPlusSquare, faSearch, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faUserMinus, faEye, faUsers, faUserShield, faUserAltSlash, faPlusSquare)

const Departments = ({departmentPermission}) => {

    const [newdepartment, openNewDepartment] = useState(false)
    const [editdepartment, openEditDepartment] = useState(false)
    const [removedepartment, openRemoveDepartment] = useState(false)
    const [departmentcreated, departmentCreated] = useState(false)
    const [departmentupdated, departmentUpdated] = useState(false)
    const [departmentdeleted, departmentDeleted] = useState(false)
    const [departmenterror, departmentError] = useState(false)
    const [departmentID, setDepartmentID] = useState('')
    const [searchText, setSearchText] = useState("")
    const [departments, setDepartments] = useState([])
    const [users, setUsers] = useState([])
    const {role} = CheckUserRole();
    const [chosenView, setChosenView] = useState("card");
    const [departmentsVisible, setDepartmentsVisible] = useState([])
    const [statsArr, setStatsArr] = useState([]);

    useEffect(() => {
        getUsers(setUsers, true);
        getDepartments(setDepartments);
        checkUserToken();
    }, [])
 
    const callDepartments = () => {
        getDepartments(setDepartments)
    }

    useEffect(() => {
        setDepartmentsVisible(
            departments.filter((department) => {
            
                let departmentAdmins = ""
                let departmentMembers = ""

                department.admins.map(d => {
                    departmentAdmins += " " + d.name
                })
                department.members.map(d => {
                    departmentMembers += " " + d.name
                })

                let searchArr = searchText.toLowerCase().split(" ")

                let visible = true

                searchArr.map(query => {

                    if(!department.name.toLowerCase().includes(query) && !departmentAdmins.toLowerCase().includes(query) && !departmentMembers.toLowerCase().includes(query)) {
                        visible = false
                    } 
                })

                return(
                    visible
                )
            })
        )          
    }, [searchText, departments]);

    useEffect(() => {
        let Ids = []
        departments.map(d => {
         Ids.push(d._id)
        })
        Ids.length > 0 && getStatistics(Ids)
    }, [departments])

    async function getStatistics(Ids)
    {

        await axios({
            method: "Post",
            url: `${endpoint}/departments/stats/`, 
            data: {
                Ids: Ids
            } ,
            headers: {
                Authorization: 'Bearer '+ localStorage.token
            }
            })
            .then(function (response) {
                if (response.status === 200) {
                    setStatsArr(response.data)
                }else{
                    console.log(response.statusText)
                }

        })
    }
    
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          To search multiple queries, add a space between each
        </Tooltip>
    );

    return (
        <Container className="main-page" fluid>  

            <Container fluid>
                {departmentupdated && <AlertComponent type="primary" text="Department Updated!" setShow={departmentUpdated}/>}
                {departmentcreated && <AlertComponent type="success" text="Department Created!" setShow={departmentCreated}/>}
                {departmentdeleted && <AlertComponent type="danger" text="Department Disabled!" setShow={departmentDeleted}/>}
                {departmenterror !== false && <AlertComponent type="danger" text={departmenterror} setShow={departmentError}/>}
            </Container> 

            <Row className="row-header">
                    <Col sm={8} xs={9}>
                        <Container>
                            <h2 className="main-header">Departments</h2>
                        </Container>
                    </Col>
                    <Col sm={4} xs={3} className="buttons">
                        <Container>
                            {role === "Admin" &&
                                <Button className="primary-button  border-button" onClick={() => {openNewDepartment(true); setDepartmentID("")}} >
                                    <FontAwesomeIcon icon={faPlusSquare}  size='1x' className='all-font-icons' />  New
                                </Button>
                            }
                        </Container>
                    </Col>
            </Row>

            <Container fluid>
                    <hr style={{backgroundColor: '#C8C8C8', width: '100%', height: '2px', marginTop: '0'}}></hr> 
            </Container> 
            <Container fluid >
                <Row style={{marginBottom: "15px", paddingLeft: "15px"}}>

                    <Col lg={9} md={7}  xs={6} className="view-col">
                            <div className="card-view-button view-button" style={{fontWeight: chosenView === "card" && "550"}} onClick={() => setChosenView("card")}>
                                {" Card View"}
                            </div>
                            <div className="view-button" style={{fontWeight: chosenView === "table" && "550"}} onClick={() => setChosenView("table")}>
                                Table View
                            </div>
                    </Col>
                        
                    <Col lg={3} md={5} xs={6}>
                        
                        <FormControl variant="filled" style={{width: "100%", marginRight: '12px'}} onChange={(e) => setSearchText(e.target.value)} >
                                <InputLabel htmlFor="input-with-icon-adornment" className="search-placeholder">
                                    Search Name and Departments
                                </InputLabel>
                                <FilledInput
                                id="input-with-icon-adornment"
                                className="search-bar"
                                endAdornment={
                                    <InputAdornment position="end" style={{color: '#224166'}}>
                                        <FontAwesomeIcon icon={faSearch} className="fontWidgetIcon" size='1x' />
                                    </InputAdornment>
                                }
                                startAdornment={
                                    <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                    >
                                    <InputAdornment position="start" style={{color: '#224166'}}>
                                        <FontAwesomeIcon icon={faInfoCircle} className="fontWidgetIcon" size='1x' />
                                    </InputAdornment>
                                    </OverlayTrigger>
                                }
                                />
                        </FormControl>
                    </Col>
        
                </Row>
            </Container>

            <Container fluid style={{padding: 0}}>
            <Row>
                {chosenView === "card" && departmentsVisible.map(d => {
                    return (
                    <Col lg={6}>
                            <DepartmentWidget 
                                department={d} 
                                role={role} 
                                openEditDepartment={openEditDepartment} 
                                openRemoveDepartment={openRemoveDepartment} 
                                setDepartmentID={setDepartmentID}
                                />
                        </Col>
                        )
                    })}
                </Row>
            </Container>
            <Container fluid>
                {chosenView === "table" &&
                    <DepartmentsTable
                        data={departmentsVisible}
                        EditModal={openEditDepartment}
                        RemoveModal={RemoveDepartmentModal}
                        SetID={setDepartmentID}
                        statsArr={statsArr}
                        role={role}
                    />
                }
            </Container>


            {newdepartment && 
            <NewDepartmentModal  
                users={users}
                newdepartment={newdepartment} 
                openNewDepartment={openNewDepartment} 
                departmentCreated={departmentCreated} 
                departmentError={departmentError} 
                callDepartments={callDepartments}
            />
            }

            {editdepartment && 
            <EditDepartmentModal  
                users={users}
                departments={departments}
                editdepartment={editdepartment} 
                departmentID={departmentID}
                openEditDepartment={openEditDepartment} 
                departmentUpdated={departmentUpdated} 
                departmentError={departmentError} 
                callDepartments={callDepartments}
            />
            }

            {removedepartment && 
            <RemoveDepartmentModal 
                departmentID={departmentID} 
                removedepartment={removedepartment} 
                openRemoveDepartment={openRemoveDepartment}
                departmentDeleted={departmentDeleted} 
                departmentError={departmentError} 
                callDepartments={callDepartments}/>
            }

        </Container>
    )
}

export default Departments
