import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Container, Row, Col, Form, InputGroup } from 'react-bootstrap'
import axios from 'axios'
import { endpoint } from '../../utils/api';

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAt, faKey, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

//CSS
import '../../css/icons/stylingIcons.css'
import '../../css/icons/password-icons.css'

library.add(faAt, faKey, faEye, faEyeSlash)

const Login = ({ setToken }) => {
    const [loginfail, setLoginFail] = useState(null)
    const [validated, setValidated] = useState(false)
    const [showpassword, setShowPassword] = useState(false)

    /*
    if (isAuthenticated) {
        return <Redirect to="/templates"/>
     }
     */

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log("Attempted to Send")
        const formData = new FormData(e.target),
             formDataObj = Object.fromEntries(formData.entries())
         
          await axios.post(`${endpoint}/auth`, formDataObj)
                    .then(function (response) {
                        if (response.status === 200) {

                            setToken(response.data.token)
                            window.location.reload()
                            
                        } else {
                            console.log(response.statusText)  
                            console.log(response.errors)                             
                            setLoginFail(response.statusText)  
                            setValidated(true)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        console.log(error.response.data)
                        setLoginFail(error.response.data)  
                        setValidated(true)
                    })
    }

    const mouseEnter = () => {
        setShowPassword(true);
    }
    const mouseLeave = () => {
        setShowPassword(false);
    }

    return (
        <div className="login-container">
                <Container className="login">
                    <Row>
                        <Col>
                            <div style={{textAlign: 'center'}}>
                                <div className="loginLogo" style={{ display: 'block' }} />
                            </div>
                            <Form 
                                onSubmit={handleSubmit}
                                title="Login"
                                description="Please enter your name and email address to login"
                                >
                                <Form.Group as={Col} controlId="formGridContactEmail">
                                    <Form.Label>Email</Form.Label>
                                    <InputGroup className="mb-2">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text><FontAwesomeIcon icon={faAt}  size='1x' className='all-font-icons' /></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control placeholder="Email..." name="email" />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid email.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridContactEmail">
                                    <Form.Label>Password</Form.Label>
                                    <InputGroup className="mb-2">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text><FontAwesomeIcon icon={faKey}  size='1x' className='all-font-icons' /></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control type={!showpassword && "password"} placeholder="Password..." name="password"/>
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid password.
                                        </Form.Control.Feedback>
                                        <InputGroup.Append>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon 
                                                    icon={!showpassword  ? faEye : faEyeSlash}
                                                    size='1x' 
                                                    className={!showpassword ? 'all-font-icons pw-show': 'all-font-icons pw-hide'} 
                                                    onMouseEnter={mouseEnter}
                                                    onMouseLeave={mouseLeave}
                                                    />
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>

                                {loginfail !== null && <span style={{color:'red', marginLeft: '16px'}}>{loginfail}</span>}

                                <div className="form-group" style={{textAlign: 'center'}}>
                                    <button
                                        className="btn primary-button btn-lg"
                                        type="submit">           
                                        Login
                                    </button>
                                </div>

                            </Form>
                        </Col>
                    </Row>
                </Container>
        </div>
    )
}


export default Login
