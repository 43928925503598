import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Container, Button} from 'react-bootstrap'
import '../../css/components/tags.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPuzzlePiece, faClock, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const DeletedSectionsCard = ({section, setOpenDeleteSection, setOpenRestoreSection, setSectionID, handleClicked, clickedSection, departmentPermission, fileSize}) => {   
            
    
     return (
            <Card className={clickedSection === section._id ? "clicked-deleted-section-card" : "section" } key={"card-" + section._id.toString()} style={{ width: '100%' }} onClick={(e) => handleClicked(e, section)} >
                <Row>
                    <Col sm={2} style={{padding: '0'}}>
                        <Card.Body style={{marginTop: '30px'}}>
                                <span className='fa-layers fa-2x fa-fw'>
                                    <FontAwesomeIcon icon={faPuzzlePiece} className="icon" />
                                </span>                           
                        </Card.Body>
                    </Col>
                    <Col sm={8} md ={7} style={{textAlign: 'left', paddingRight: '0', paddingLeft: '10px', position: 'relative', minHeight: '140px'}} >
                        <Card.Body style={{padding: '0', paddingTop: '15px'}}>
                            <Card.Title style={{marginBottom: '6px'}}>{ section.name }</Card.Title>
                            <Card.Subtitle className="tag-updated-by new-line" style={{marginBottom: '20px'}}>
                                { section.description } 
                            </Card.Subtitle>

                            <Card.Subtitle className="tag-updated-by new-line" style={{ position: 'absolute', bottom: '0'}}>
                                {(fileSize/1000000).toFixed(1) + " MB" }
                            </Card.Subtitle>

                        </Card.Body>
                    </Col>
                    <Col sm={1} md={2} style={{padding: '0', textAlign: 'center', paddingLeft: '5px'}}>
                        <Card.Body style={{padding: '0', paddingTop: '30px'}}>
                            {departmentPermission && (
                                <>
                                        <Button className="primary-button border-button" style={{height: '35px', width: '38px', marginBottom: '5px' }} onClick={() => { setOpenRestoreSection(true); setSectionID(section._id)}}>
                                            <FontAwesomeIcon icon={faClock}  size='1x' className='all-font-icons' style={{fontSize: '13px', marginBottom: '2px'}}/> 
                                        </Button>

                                        <Button variant="danger" style={{height: '35px', width: '38px' }} onClick={(e) => {setOpenDeleteSection(true); setSectionID(section._id) }}>
                                            <FontAwesomeIcon icon={faTrashAlt}  size='1x' className='all-font-icons' style={{fontSize: '13px', marginBottom: '2px'}}/> 
                                        </Button>    
                                </>     
                            )}
                        </Card.Body>
                    </Col>
                </Row>
            </Card>    
    )

}

export default DeletedSectionsCard