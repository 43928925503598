import React, { useEffect, useState } from 'react'
import { Modal, Form,InputGroup, Button } from 'react-bootstrap'
import axios from 'axios'
import { endpoint, getReport } from '../../utils/api'
import '../../css/components/report.css'
import SaveButton from '../../elements/SaveButton'

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature, faHeading, faHistory } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faHeading, faFileSignature, faHistory)

const ReportModal = ({setOpenNewModal, openNewModal, reportCreated, reportUpdated, reportError, getReports, setReports, reportID }) => {
    const [validated, setValidated] = useState(false)
    const [report, setReport] = useState([]);
    const [errormessage, setErrorMessage] = useState("");
    const [changeFile, setChangeFile] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (reportID !== "" ) {
            getReport(setReport, reportID)
        }
    }, [reportID])
    
    const change  = [
      {
          label: "Yes",
          name: "Yes"
      },
      {
          label:"No",
          name: "No"
      }
  ]

  useEffect(() => {
    errormessage !== "" ? setLoading(true) : setLoading(false)
  }, [errormessage])

    const handleSubmit = async (e, props) => {
        setLoading(true)

        e.preventDefault();
        const form = new FormData();
        const fileInput = document.querySelector("#fileInput");
        const name = document.querySelector('#name');
        const description = document.querySelector('#description')

        if ( report.length === 0 || changeFile === true ) {
          form.append('file', fileInput.files[0],  fileInput.files[0].name);
          }
          form.append('name', name.value);
          form.append('description', description.value );

        let method;

        if ( reportID === "" ) {
            method = "post";
        } else {
            method = "put";
            form.append('reportID', reportID);
        }

        await axios({
            method: method,
            url: `${endpoint}/reports`, 
            data: form,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer '+ localStorage.token
                },
        })
            .then(function (response) {
                
                if (response.status === 200) {
                    if ( reportID === "" ) {
                        reportCreated(true);
                    } else {
                        reportUpdated(true)
                    }
                    setOpenNewModal(false);
                    getReports(setReports);
                    setLoading(false)
                } else {
                    reportError(response.statusText);
                    setOpenNewModal(false);
                    console.log(response.statusText)  
                    console.log(response.errors)  
                    setLoading(false)        
                }
            })
            .catch((error) => {
                reportError(error.response.data.err)
                setLoading(false)
                setOpenNewModal(false);
            })
        
    }
    
    const checkFileType = (e) => {
      if (!e.target.value.includes('docx')){
        setErrorMessage("File must be of type .docx")
      } else {
        setErrorMessage("")
      }
    }
    const fileChange = (e) => {
      if (e.target.value === "Yes") {
        setChangeFile(true);
      } else {
        setChangeFile(false);
      }
    }

    return (
        <Modal backdropClassName="backdrop" show={openNewModal} onHide={() => setOpenNewModal(false)}>
        <Modal.Header className="heading">
          <Modal.Title >
            {reportID !== "" ? "Edit File" : "Add File"}
          </Modal.Title>
        </Modal.Header>
  
        <Form onSubmit={handleSubmit} noValidate validated={validated} >
          <Modal.Body>
            <Form.Label className="bolder">File Name</Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                  <InputGroup.Text><FontAwesomeIcon icon={faFileSignature}  size='1x' className='all-font-icons' /></InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control type="text" placeholder="File Name..." id="name" name="name" defaultValue={report.length !== 0 ? report.name : ""} required />
              <Form.Control.Feedback type="invalid">Please choose a name.</Form.Control.Feedback>
            </InputGroup>
  
            <Form.Label className="bolder">File Description</Form.Label>
            <InputGroup className="mb-2">
            <InputGroup.Prepend>
                  <InputGroup.Text><FontAwesomeIcon icon={faHeading}  size='1x' className='all-font-icons' /></InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="File description..."
                id="description"
                name="description"
                defaultValue={report.length !== 0 ? report.description : ""}
                required
              />
              <Form.Control.Feedback type="invalid">Please choose a description.</Form.Control.Feedback>
            </InputGroup>


            {report.length !== 0 ? 
            <>
              <Form.Label className="bolder">Change File?</Form.Label>
              <InputGroup className="mb-2">
              <InputGroup.Prepend>
                    <InputGroup.Text><FontAwesomeIcon icon={faHistory}  size='1x' className='all-font-icons' /></InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control as="select" name="file-change" onChange={(e) => {fileChange(e)} } >
                      {change.map((option) => {                                                                                  
                        return <option selected={option.name} value={option.name} >{option.label}</option>
                      })}                             
                  </Form.Control>
                <Form.Control.Feedback type="invalid">Please choose a description.</Form.Control.Feedback>
              </InputGroup>
              </>
            :
            <>
            <Form.Label className="bolder">File</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="file"
                id="fileInput"
                name="file"
                defaultValue={report.length !==0 ? report.filename : ""}
                onChange={(e) => checkFileType(e)}
                required
              />
              <Form.Control.Feedback type="invalid">Please choose a File.</Form.Control.Feedback>
              {errormessage !== "" && <p className='error-message'>{errormessage}</p>}
            </InputGroup>
            </>
            }
           
            {changeFile === true && 
             <>
            <Form.Label className="bolder">File</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="file"
                id="fileInput"
                name="file"
                onChange={(e) => checkFileType(e)}
                required
              />
              <Form.Control.Feedback type="invalid">Please choose a File.</Form.Control.Feedback>
              {errormessage !== "" && <p className='error-message'>{errormessage}</p>}
            </InputGroup> 
            </>
            }
  

          </Modal.Body>
  
          <Modal.Footer className="footer">  
            <Button variant="secondary" onClick={() => setOpenNewModal(false)}>
              Close
            </Button>
            <SaveButton loading={loading} />     
               
          </Modal.Footer>
          
        </Form>
  
      </Modal>
    )

    }
export default ReportModal
 
{/* <form class="uploadForm" encType="multipart/form-data" headers={'Bearer ' + localStorage.token}>
<label class="control-label">Select File</label>
<input name="file" id="fileInput" type="file" class="file" />
<label class="control-label">Name</label>
<input name="name" id="name" type="text"/>
<label class="control-label">Description</label>
<input name="description" id="description" type="text"/>
<input type="submit" value="submit" onClick={(e) => handleSubmit(e)} />
</form>  */}
