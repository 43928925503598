//General search bar, more complex ones such as /users have their own component
import { Container, Row, Col  } from "react-bootstrap"
import {FormControl, InputLabel, InputAdornment, FilledInput } from '@mui/material'

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const SearchBar = ({setChosenView, chosenView, setSearchText, placeholder}) => {
    return (
        <Container fluid >
            <Row style={{marginBottom: "15px", paddingLeft: "15px"}}>

                <Col lg={9} md={7} sm={6} xs={6} className="view-col">
                        <div className="card-view-button view-button" style={{fontWeight: chosenView === "card" && "550"}} onClick={() => setChosenView("card")}>
                            {"Card View"}
                        </div>
                        <div className="view-button" style={{fontWeight: chosenView === "table" && "550"}} onClick={() => setChosenView("table")}>
                            Table View
                        </div>
                </Col>
                    
                <Col lg={3} md={5} sm={6} xs={6}>
                    
                    <FormControl variant="filled" style={{width: "100%", marginRight: '12px'}} onChange={(e) => setSearchText(e.target.value)} >
                            <InputLabel htmlFor="input-with-icon-adornment" className="search-placeholder">
                                {placeholder}
                            </InputLabel>
                            <FilledInput
                            id="input-with-icon-adornment"
                            className="search-bar"
                            endAdornment={
                                <InputAdornment position="end" style={{color: '#224166'}}>
                                    <FontAwesomeIcon icon={faSearch} className="fontWidgetIcon" size='1x' />
                                </InputAdornment>
                            }
                            />
                    </FormControl>
                </Col>
    
            </Row>
        </Container>
    )
}

export default SearchBar