import React, {useEffect} from 'react'
import { Card, Badge, Button, Row, Col } from 'react-bootstrap'
import '../../css/components/sections.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faArrowCircleUp)
const RevisionCard = ({revision, setRevisionSelected, revisionSelected, openChangeRevision, section}) => {
    let selected = false;
    selected = revision._id === revisionSelected && true

    return (
        <Card className={selected ? "revision-card-selected" : "revision-card"} onClick={() => setRevisionSelected(revision._id)} >
            <Card.Body>
                <Row>
                <Col lg={selected ? 9 : 12} style={{marginRight: selected && '0'}}>
                <Card.Title className='revision-title'>{section.revision === revision.revision ? "Active Revision ("+revision.revision+")" :"Revision "+ revision.revision}</Card.Title>
                <Card.Subtitle className="revision-changes">
                            {revision.changes !== undefined && `"` + revision.changes.trim() + `"`}
                </Card.Subtitle>
                <Card.Text className="text-muted revision-author">{revision.author?.name + ", " + revision.createdAt.slice(0,10).split("-").reverse().join('/')}</Card.Text>   
                </Col>
                {selected &&
                <Col lg={3}>
            
                <Button className="save-button" className="primary-button  border-button" type="submit" onClick={() => openChangeRevision(true)} disabled={section.revision === revision.revision} style={{marginTop: '15px', padding: '3px 8px'}}>
                    <FontAwesomeIcon icon={faArrowCircleUp}  size='1x' className='all-font-icons' /> 
                </Button>        
      
                </Col>  
                   }
                </Row>                        
            </Card.Body>
        </Card>           
    )
}

export default RevisionCard
