import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios'
import { endpoint } from '../../utils/api'
import DeleteButton from '../../elements/DeleteButton'

const RemoveReportModal = ({ reportID, removeReport, setOpenRemoveReport, reportDeleted, reportError, getReports, setReports }) => {
    const [loading, setLoading] = useState(false);

    const deleteReport = async () => {
        setLoading(true)
        await axios({
                    method: "DELETE",
                    url: `${endpoint}/reports/${reportID}`,
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token
                    }
                })
                .then(function (response) {
                    if (response.status === 200) {                
                        reportDeleted(true);
                        setOpenRemoveReport(false);
                        getReports(setReports);
                        setLoading(false)
                    } 
                })
                .catch((error) => {
                    reportError("Error")
                    setOpenRemoveReport(false)
                    setLoading(false)
                })
    }

    return (
        <Modal show={removeReport !== false ? true : false} onHide={() => setOpenRemoveReport(false)}>
            <Modal.Header className="delete-header" closeButton>
                <Modal.Title>Delete File</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className='modal-delete-text'>Are you sure you want to delete this File?</p>
            </Modal.Body>

            <Modal.Footer className="footer">
                <Button variant="secondary" onClick={() => setOpenRemoveReport(false)}>Close</Button>
                <DeleteButton onClick={deleteReport} loading={loading} />
            </Modal.Footer>
        </Modal>
    )
}

export default RemoveReportModal