import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { TableSortLabel, Box, TablePagination, tableCellClasses } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const DepartmentsTable = ({data, EditModal, SetID, RemoveModal, statsArr, role}) => {

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [isOrderByNumeric, setIsOrderByNumeric] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    function createData(name, admins, members, numberSections, numberTemplates, actions) {
    return {
        name,
        admins,
        members,
        numberSections,
        numberTemplates,
        actions
    };
    }
    
    const rows = [];


    data.map((d, index) => {

        let alphabeticalAdmins = []
        let adminString = "" 
        let alphabeticalMembers = []
        let memberString = "" 

        d.admins.map(a => {
            alphabeticalAdmins.push(a.name) 
        })
        d.members.map(m => {
            alphabeticalMembers.push(m.name) 
        })
        alphabeticalAdmins.sort();
        alphabeticalMembers.sort();
        
        alphabeticalAdmins.map((d, index) => {
            alphabeticalAdmins.length === index+1 ? adminString += d : adminString += d + ", "
        })
        alphabeticalMembers.map((d, index) => {
            alphabeticalMembers.length === index+1 ? memberString += d : memberString += d + ", "
        })

        let departmentStats = [];

        statsArr.map(s => {
            d._id == s.id &&  departmentStats.push(s)            
        })

        rows.push(createData(
            d.name,
            adminString,
            memberString,
            departmentStats[0]?.numberSections,
            departmentStats[0]?.numberTemplates,
        ))
    })

   

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }
      
    function getComparator(order, orderBy) {

        return order === 'desc' 
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // This method is created for cross-browser compatibility, if you don't
    // need to support IE11, you can use Array.prototype.sort() directly
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
        const order = isOrderByNumeric ? comparator(b[0], a[0]) : comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const createSortHandler = (property, numeric) => (event) => {
        setIsOrderByNumeric(numeric ? true : false)
        handleRequestSort(event, property);
    };

    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };   


    const handleEdit = (name, e) => {
        if(role === "Admin") {
            let id;
            if (e?.target.innerText !== "delete") {
                Object.keys(data).map(i => {
                    if(name === data[i].name) {
                        id = data[i]._id 
                    }
                })
        
                id !== undefined && SetID(id)
                id !== undefined && EditModal(true)
            }
        }
    }

    const handleDelete = (name) => {
        if(role === "Admin") {
            let id;

            Object.keys(data).map(i => {
                if(name === data[i].name) {
                    id = data[i]._id 
                }
            })

            id !== undefined && SetID(id)
            id !== undefined && RemoveModal(true)
        }
    }



    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: "#3D7BDC",
          color: "#f2f2f2",
          fontSize:14,
          fontWeight: 450
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: "#F3F3F3",
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
        cursor: role === "Admin" && "pointer",
        //change hover colour
        "&:hover": {
            backgroundColor: "#DCEAFD !important"
          }
    }));

    const useStyles = makeStyles({
        root: {
          '&$active': {
            color: "#fff",
          },
          "&:hover": {
            color: "#fff"
          },
        },
        active: {}, // pseudo
    });

    const cls = useStyles()

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const headCells = [
        {
          id: 'name',
          numeric: false,
          disablePadding: true,
          label: 'Name',
        },
        {
          id: 'admins',
          numeric: false,
          disablePadding: false,
          label: 'Admins',
        },
        {
            id: 'members',
            numeric: false,
            disablePadding: false,
            label: 'Members',
        },
        {
          id: 'numberSections',
          numeric: true,
          disablePadding: false,
          label: 'Number of Sections',
        },
        {
            id: 'numberTemplates',
            numeric: true,
            disablePadding: false,
            label: 'Number of Templates',
          },
        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Actions',
            align: "center"
        },
    ];

   

    return (
        <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <StyledTableCell
                                    key={headCell.id}
                                    align={headCell.align !== undefined ? headCell.align : headCell.numeric ? 'center' : 'left'}
                                    padding="5px"
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id, headCell.numeric)}
                                        classes={{ root: cls.root, active: cls.active }}
                                    >
                                        {headCell.label}

                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {

                            return (
                            <StyledTableRow
                                hover
                                onClick={(e) => handleEdit(row.name, e)}
                                tabIndex={-1}
                                key={row.name}
                            >
                                <StyledTableCell
                                    component="th"
                                    scope="row"
                                    padding="5px"
                                >
                                    {row.name}
                                </StyledTableCell>

                                <StyledTableCell >{row.admins}</StyledTableCell>
                                <StyledTableCell >{row.members}</StyledTableCell>
                                <StyledTableCell align="center">{row.numberSections}</StyledTableCell>
                                <StyledTableCell align="center">{row.numberTemplates}</StyledTableCell>
                                <StyledTableCell >
                                    {role === "Admin" &&
                                        <div style={{width: '100%'}}>
                                            <div style={{display: "inline-block"}}> 
                                                <button className="btn primary-button btn-sm" onClick={()=> handleEdit(row.name)}><i className="material-icons">edit</i></button>
                                            </div>
                                            <div style={{display: "inline-block", paddingLeft: '3px'}}> 
                                                <button className="btn btn-danger btn-sm" onClick={()=> handleDelete(row.name)}><i className="material-icons">delete</i></button>
                                            </div>                                    
                                        </div>
                                    }
                                </StyledTableCell>
                            </StyledTableRow>
                            );
                        })}

                        {emptyRows > 0 && (
                            <TableRow
                            style={{
                                height: (53) * emptyRows,
                            }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}

                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

        </Paper>

    );      

}


export default DepartmentsTable