import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { TableSortLabel, Box, TablePagination, tableCellClasses } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

const TemplatesTable = ({data, EditModal, SetID, RemoveModal, departmentPermission}) => {

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [isOrderByNumeric, setIsOrderByNumeric] = useState(false)
    const [isOrderByDate, setIsOrderByDate] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);


    function createData(name, description, category, numberSections, author, createdAt, lastUpdatedBy, lastUpdatedAt) {
    return {
        name,
        description,
        category,
        numberSections,
        author,
        createdAt,
        lastUpdatedBy,
        lastUpdatedAt
    };
    }    
    const rows = [];

    data.map((t, index) => {
        
        rows.push(createData(
            t.name,
            t.description,
            t.category.title,
            t.sections.length,
            t.author.name,
            t.createdAt.slice(0,10).split("-").reverse().join('/'),
            t.last_updated_by.name,
            t.updatedAt.slice(0,10).split("-").reverse().join('/')
        ))
    })

   

    function descendingComparator(a, b, orderBy) {

        if(isOrderByDate) {
            let dateA = moment(b[orderBy], 'DD/MM/YYYY')
            let dateB = moment(a[orderBy], 'DD/MM/YYYY')

            if(moment(dateA).isAfter(dateB)){
                return -1;
            }
            if (moment(dateA).isBefore(dateB)){
                return 1;
            }
            return 0
        } else {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
            return 1;
            }
            return 0;
        }
        
    }
      
    function getComparator(order, orderBy) {

        return order === 'desc' 
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // This method is created for cross-browser compatibility, if you don't
    // need to support IE11, you can use Array.prototype.sort() directly
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
        const order = isOrderByNumeric ? comparator(b[0], a[0]) : comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const createSortHandler = (property, numeric, date) => (event) => {
        setIsOrderByDate(date ? true : false)
        setIsOrderByNumeric(numeric ? true : false)
        handleRequestSort(event, property);
    };

    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };   


    const handleRowClick = (e, name) => {
        if(departmentPermission) {
            let id;

            data.map(c => {
                if(name === c.name) {
                    id = c._id 
                }
            })
            if (e.target.innerText === "delete"){
                id !== undefined && RemoveModal(true)
                id !== undefined && SetID(id)
            } else {
                id !== undefined && EditModal(id)
                id !== undefined && SetID(id)
            }   
        }       
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: "#3D7BDC",
          color: "#f2f2f2",
          fontSize:14,
          fontWeight: 450
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: "#F3F3F3",
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
        cursor: departmentPermission && "pointer",
        //change hover colour
        "&:hover": {
            backgroundColor: "#DCEAFD !important"
          }
    }));

    const useStyles = makeStyles({
        root: {
          '&$active': {
            color: "#fff",
          },
          "&:hover": {
            color: "#fff"
          },
        },
        active: {}, // pseudo
    });

    const cls = useStyles()

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'description',
            numeric: false,
            disablePadding: true,
            label: 'Description',
        },
        {
            id: 'category',
            numeric: false,
            disablePadding: true,
            label: 'Category',
        },
        {
            id: 'numberSections',
            numeric: true,
            disablePadding: false,
            label: 'Number of Sections',
        },
        {
            id: 'author',
            numeric: false,
            disablePadding: false,
            label: 'Created By'
        },
        {
            id: 'createdAt',
            numeric: false,
            date: true,
            disablePadding: false,
            label: 'Created On',
        },
        {
            id: 'lastUpdatedBy',
            numeric: false,
            disablePadding: false,
            label: 'Last Updated By',
        },
        {
            id: 'lastUpdatedAt',
            numeric: false,
            date: true, 
            disablePadding: false,
            label: 'Last Updated On',
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Actions',
            align: "center"
        },
    ];

   

    return (
        <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <StyledTableCell
                                    key={headCell.id}
                                    align={headCell.align !== undefined ? headCell.align : headCell.numeric ? 'center' : 'left'}
                                    padding="5px"
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id, headCell.numeric, headCell.date)}
                                        classes={{ root: cls.root, active: cls.active }}
                                    >
                                        {headCell.label}

                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {

                            return (
                            <StyledTableRow
                                hover
                                onClick={(e) => handleRowClick(e, row.name)}
                                tabIndex={-1}
                                key={row.name}
                            >
                                <StyledTableCell
                                    component="th"
                                    scope="row"
                                    padding="5px"
                                >
                                    {row.name}
                                </StyledTableCell>
                                <StyledTableCell >{row.description}</StyledTableCell>
                                <StyledTableCell >{row.category}</StyledTableCell>
                                <StyledTableCell align="center">{row.numberSections}</StyledTableCell>
                                <StyledTableCell >{row.author}</StyledTableCell>
                                <StyledTableCell >{row.createdAt}</StyledTableCell>
                                <StyledTableCell >{row.lastUpdatedBy}</StyledTableCell>
                                <StyledTableCell >{row.lastUpdatedAt}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {departmentPermission &&
                                        <div style={{width: '100%'}}>
                                            <div style={{display: "inline-block"}}> 
                                                <button className="btn primary-button btn-sm" ><i className="material-icons">edit</i></button>
                                            </div>
                                            <div style={{display: "inline-block", paddingLeft: '3px'}}> 
                                                <button className="btn btn-danger btn-sm" ><i className="material-icons">delete</i></button>
                                            </div>                                    
                                        </div>
                                    }
                                </StyledTableCell>
                            </StyledTableRow>
                            );
                        })}

                        {emptyRows > 0 && (
                            <TableRow
                            style={{
                                height: (53) * emptyRows,
                            }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}

                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

        </Paper>

    );
        
    


}


export default TemplatesTable