import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button  } from 'react-bootstrap';
import '../../css/components/departments.css'
import { getDepartmentPermissions } from "../../utils/api";
//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faUserShield, faUser } from "@fortawesome/free-solid-svg-icons";
import { Chip, Avatar } from '@mui/material';

const DepartmentWidget = ({department, role, openEditDepartment, openRemoveDepartment, setDepartmentID }) => {
    const [departmentPermission, setDepartmentPermission] = useState(false);

    useEffect(() => {
        getDepartmentPermissions(setDepartmentPermission, department.name)
    },[department])

    return (
        <Container className="department-widget" >
            <Row>
                <Col md={9} style={{textAlign: 'center', color: '#224166'}} >
                    <h5 style={{fontSize: '25px'}}>{department.name}</h5>
                </Col>

                <Col md={3} style={{textAlign: 'right', padding:'0'}}>
                <Container style={{marginTop:'-5px', padding: '0', marginLeft: '0'}}>
                {departmentPermission &&
                    <>
                        <Button className="primary-button border-button" style={{height: '35px', width: '38px', marginRight: "2px" }} onClick={() => { openEditDepartment(true); setDepartmentID(department._id)}}>
                            <FontAwesomeIcon icon={faPen}  size='1x' className='all-font-icons' style={{fontSize: '13px', marginBottom: '2px'}}/> 
                        </Button>                   
                    </>
                }
                {role === "Admin" &&
                    <Button variant="danger" style={{height: '35px', width: '38px' }} onClick={() => {openRemoveDepartment(true); setDepartmentID(department._id) }}>
                    <FontAwesomeIcon icon={faTrash}  size='1x' className='all-font-icons' style={{fontSize: '13px', marginBottom: '2px'}}/> 
                    </Button>
                }
                </Container>
                </Col>
            </Row>

            <Row style={{marginTop: '10px'}}> 

                <Col>
                    <Row  style={{paddingLeft: '12px'}}>
                        <h5 style={{fontSize: '18px', color: '#2b5281'}}>Department Admins</h5>
                    </Row>
                    <Row>
                        {department.admins.map( a => {
                            return (
                                <Col lg={3} sm={4} xs={6} style={{textAlign: 'left'}}>
                                    <Chip 
                                    className="admin-chip"
                                    avatar={
                                    <Avatar className= "avatar-icon">
                                    <FontAwesomeIcon icon={a.role === "Admin" ? faUserShield : faUser}  
                                    size='1x' 
                                    className='all-font-icons department-chip-icon' 
                                    />
                                    </Avatar>
                                    } 
                                    label={a.name}
                        
                                    />
                                </Col>
                            )
                        })}
                    </Row>
                </Col>
            </Row>

            <Row style={{marginTop: '20px'}}>
                <Col>
                    <Row style={{paddingLeft: '12px'}}>
                        <h5 style={{fontSize: '18px', color: '#2b5281'}}>Department Members</h5>
                    </Row>

                    <Row>
                        {department.members.map( m => {
                            return (
                                <Col lg={3} sm={4} xs={6} style={{textAlign: 'left'}}>
                                    <Chip 
                                    className="admin-chip"
                                    avatar={
                                    <Avatar className= "avatar-icon">
                                         <FontAwesomeIcon icon={m.role === "Admin" ? faUserShield : faUser}  
                                            size='1x' 
                                            className='all-font-icons department-chip-icon' 
                                        />
                                    </Avatar>
                                    } 
                                    label={m.name}
                        
                                    />
                                </Col>
                            )
                        })}
                    </Row>
                </Col>


            </Row>

        </Container>
    )
}


export default DepartmentWidget