import { useEffect, useState } from 'react';
import { Nav, Navbar, Container, Row, Col, Button } from 'react-bootstrap';
import UserIcon from '../components/auth/UserIcon'
import Logo from '../components/assets/images/MA Logo-no text.png'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { getDesignatedDepartments } from './api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";



library.add(faListUl)

const TopNavbar = ({setToggled, toggled, collapsed, setCollapsed, setChosenDepartment, chosenDepartment}) => {
    const animatedComponents = makeAnimated();
    const [designatedDepartments, setDesignatedDepartments] = useState([]);

    useEffect(() => {
        getDesignatedDepartments(setDesignatedDepartments)
    },[])
    useEffect(() => {
      designatedDepartments.length !== 0 && setChosenDepartment(designatedDepartments[0].value)  
    }, [designatedDepartments])

    return (
        <Navbar variant="dark"  className={collapsed ? "sidebar-collapsed-top-nav top-navbar" :"top-navbar" } >  

        <Container fluid>      

            <Button variant="light" className="toggle-button" onClick={() => {setToggled(!toggled); setCollapsed(false)}}>
            <FontAwesomeIcon icon={faListUl}  size='1x' className='sidebar-control-icon' onClick={() => {setToggled(!toggled); setCollapsed(false)}} />
            </Button>
            
            <Button variant="light" className="collapse-button" onClick={() => setCollapsed(!collapsed)}>
            <FontAwesomeIcon icon={faEllipsisV}  size='1x' className='sidebar-control-icon' onClick={() => setCollapsed(!collapsed)} />
            </Button>
        


            <div className="department-select">
                <Select
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    value={{label:chosenDepartment}}
                    options={designatedDepartments}
                    onChange={(e) => {setChosenDepartment(e.value)}}
                
                />
            </div>



            <div style={{float: 'right', marginRight: "18px"}}>
            <UserIcon />
            </div>
            </Container>

      </Navbar>
    )
}

export default TopNavbar