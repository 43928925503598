import React, { useState, useEffect } from 'react'
import { Modal, Button, Table, Container, Spinner } from 'react-bootstrap'
import axios from 'axios'
import { endpoint } from '../../utils/api'
import DeleteButton from '../../elements/DeleteButton'
//CSS
import '../../css/components/categories.css'
const RemoveCategoryModal = ({ categoryID, removecategory, openRemoveCategory, categoryDeleted, categoryError, getCategories, setCategories, chosenDepartment, stats }) => {
    const [categoryStats, setCatergoryStats] = useState({});
    const [deleteAllowed, setDeleteAllowed] = useState(false);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let total = 0;
        if (stats !== undefined) {

            stats.map(s => {
                if(s.id == categoryID) {
                    setCatergoryStats(s)
                    total += s.numberSections
                    total += s.numberTemplates
                }
            })

            if (total === 0) {
                setDeleteAllowed(true);
            }
        }
        
    }, [stats])

    const removeCategory = async () => {
        setLoading(true)
        await axios({
                    method: "DELETE",
                    url: `${endpoint}/categories/${chosenDepartment}/${categoryID}`,
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token,
                        DepartmentName: chosenDepartment
                    }
                })
                .then(function (response) {
                    if (response.status === 200) {                    
                        categoryDeleted(true)
                        openRemoveCategory(false)
                        getCategories(setCategories, chosenDepartment)
                        setLoading(false)
                    } 
                })
                .catch((error) => {
                    categoryError(error.response.data.msg)
                    openRemoveCategory(false)
                    setLoading(false)
                })
    }

    return (
        <Modal show={removecategory !== false ? true : false} onHide={() => openRemoveCategory(false)}>
            <Modal.Header closeButton className="delete-header">
                <Modal.Title >Delete category</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Container className="modal-container">
                {!deleteAllowed ? 
                <p>Unable to delete as there are templates/sections still saved</p>
                :
                 <p>No templates/sections saved to the category</p>
                 }
                 {!deleteAllowed &&
                <Table striped hover>
                        <thead>
                        <tr>
                        <th>Type</th>
                        <th>Number</th>
                        </tr>
                    </thead>      
                    <tbody>
                            <tr className="striped">
                                <td>Sections</td>
                                <td>{categoryStats.numberSections}</td>
                            </tr>
                            <tr className="striped">
                                <td>Templates</td>
                                <td>{categoryStats.numberTemplates}</td>
                            </tr>

                        
                    </tbody>              
                
                </Table>
                }
                {!deleteAllowed ? 
                <p className="warning-delete">Save the templates/sections to another category</p>
                :
                 <p className="success-delete">This category can be deleted</p>
                 }
            </Container>
            </Modal.Body>

            <Modal.Footer className="footer">
                <Button variant="secondary" onClick={() => openRemoveCategory(false)}>Close</Button>
                {deleteAllowed && 
                    <DeleteButton onClick={removeCategory} loading={loading} />
                }
            </Modal.Footer>
        </Modal>
    )
}

export default RemoveCategoryModal