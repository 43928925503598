/* eslint-disable no-extend-native */
import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import AlertComponent from '../alerts/AlertComponent'
import { endpoint } from '../../utils/api'
import TagModal from './TagModal'
import RemoveTagModal from './RemoveTagModal'
import { getTags } from '../../utils/api'
import { checkUserToken } from '../../utils/checkUser'
import makeAnimated from 'react-select/animated';
import TagsTable from './TagsTable'
import TagsCard from './TagsCard';
import SearchBar from '../../elements/SearchBar'
import axios from 'axios'
//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faShareSquare, faPlusSquare)
const animatedComponents = makeAnimated();

const Tags = ({chosenDepartment, departmentPermission}) => {

    const [openNewModal, setOpenNewModal] = useState(false);
    const [removeTag, setOpenRemoveTag] = useState(false)
    const [tagcreated, tagCreated] = useState(false);
    const [tagerror, tagError] = useState(false);
    const [tagdeleted, tagDeleted] = useState(false)
    const [tagupdated, tagUpdated] = useState(false)
    const [tagID , setTagID] = useState("")
    const [chosenView, setChosenView] = useState("card")
    const [tags, setTags] = useState([]);
    const [statsArr, setStatsArr] = useState([]);
    const [tagsVisible, setTagsVisible] = useState([]);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {   
        checkUserToken();
    }, []);

    useEffect(() => {
        if (chosenDepartment !== null && chosenDepartment !== undefined) {
            getTags(setTags, chosenDepartment);
        }
    }, [chosenDepartment])
    

    //Filter Level 1
    useEffect(() => {
        setTagsVisible(
            tags.filter((t) => {
            return(
                t.name.toLowerCase().includes(searchText.toLowerCase().trim())
                )
            })
        )          
    }, [searchText, tags]);

    useEffect(() => {
        let names = []
        tags.map(t => {
            names.push(t.name)
        })
        names.length > 0 && getStatistics(names)
    }, [tags])

    async function getStatistics(names)
    {

        await axios({
            method: "Post",
            url: `${endpoint}/tags/stats/`, 
            data: {
                names: names,
                department: chosenDepartment
            } ,
            headers: {
                Authorization: 'Bearer '+ localStorage.token,
                departmentName: chosenDepartment
            }
            })
            .then(function (response) {
                if (response.status === 200) {
                    setStatsArr(response.data)
                }else{
                    console.log(response.statusText)
                }

        })
    }
    
    return (
        <Container className="main-page" fluid>

            <Container fluid>          
                {tagcreated && <AlertComponent type="success" text="Tag Created!" setShow={tagCreated}/>}
                {tagerror && <AlertComponent type="danger" text="Error" setShow={tagError}/>}
                {tagdeleted && <AlertComponent type="danger" text="Tag Deleted!" setShow={tagDeleted}/>}
                {tagupdated && <AlertComponent type="primary" text="Tag Updated!" setShow={tagUpdated}/>}
            </Container>  
    
            <Row className="row-header">
                    <Col sm={8} xs={9}>
                        <Container>
                            <h2 className="main-header">{chosenDepartment} Tags</h2>
                        </Container>
                    </Col>
                    <Col sm={4} xs={3} className="buttons">
                        <Container>
                            {departmentPermission &&
                                <Button className="primary-button  border-button" onClick={() => {setOpenNewModal(true); setTagID("")}} >
                                    <FontAwesomeIcon icon={faPlusSquare}  size='1x' className='all-font-icons' />  New
                                </Button>
                            }
                        </Container>
                    </Col>
            </Row>          
         
            <Container fluid>
                    <hr style={{backgroundColor: '#C8C8C8', width: '100%', height: '2px', marginTop: '0'}}></hr> 
            </Container> 

            <SearchBar 
                setChosenView={setChosenView} 
                chosenView={chosenView} 
                setSearchText={setSearchText}
                placeholder="Search Tags"
            />
            
            <Container fluid>
                <Row>
                    {chosenView === "card" && tagsVisible.map(tag => {
                        return (
                            <Col lg={3} xs={6} >
                                <TagsCard tag={tag} setOpenRemoveTag={setOpenRemoveTag} setOpenNewModal={setOpenNewModal} setTagID={setTagID} departmentPermission={departmentPermission} />
                            </Col>
                
                        )
                    })}
                    {chosenView == "table" && 
                    <Container fluid>
                        <TagsTable
                            data={tagsVisible} 
                            EditModal={setOpenNewModal} 
                            SetID={setTagID} 
                            RemoveModal={setOpenRemoveTag} 
                            statsArr={statsArr}
                            departmentPermission={departmentPermission}
                        />
                        </Container>
                    }
                </Row>
            </Container>
            
            {openNewModal && 
            <TagModal 
                openNewModal={openNewModal} 
                setOpenNewModal={setOpenNewModal} 
                tagCreated={tagCreated} 
                tagUpdated={tagUpdated}
                tagError={tagError}  
                getTags={getTags} 
                setTags={setTags}
                tagID={tagID} 
                tags={tags}
                chosenDepartment={chosenDepartment}/>
            }

            {removeTag && 
            <RemoveTagModal 
                tagID={tagID} 
                removeTag={removeTag} 
                setOpenRemoveTag={setOpenRemoveTag} 
                tagDeleted={tagDeleted} 
                tagError={tagError} 
                setTags={setTags}
                getTags={getTags} 
                chosenDepartment={chosenDepartment}/>
            }


        </Container>
    )
}

export default Tags