import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { endpoint } from '../../utils/api';
import '../../css/components/report.css'
import Select from 'react-select'
import RemoveTemplateModal from './RemoveTemplateModal';
import makeAnimated from 'react-select/animated'
//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const animatedComponents = makeAnimated();

const TemplateWidget = ({template, users, openTemplate, openRemoveTemplate, setTemplateID, departmentPermission, department }) => {

    
    const history = useHistory();

    const [chosenSection, setChosenSection] = useState(null)
    const [chosenSectionID, setChosenSectionID] = useState(null)

    let sections = []
    template.sections.map(s => {
        sections.push({
            label: s.name,
            value: s.name
        })
    })

    useEffect(() => {
        setChosenSection(template?.sections[0]?.name)
    },[template])
    useEffect(() => {
        template.sections.map(s => {
                return s.name === chosenSection && setChosenSectionID(s._id)
        })
    }, [chosenSection])

    const OpenSection = (ID) => {
        window.open(`https://www.mach-intranet.com/sections/${department}/${ID}`);
    }
    return (
    <Container className="report-widget">

        <Row style={{marginBottom: '0px'}}>
            <Col lg={12} style={{paddingLeft: '0'}}>
                <h5 style={{textAlign: 'left'}}>{template.name}</h5>
            </Col>
        </Row>

        <Row style={{marginBottom: '4px'}}>
            <Col md={10} sm={8} style={{paddingLeft: '0'}}>
            <h5 style={{textAlign: 'left', color: '#504F4F', fontSize: '15px',  marginTop:'5px'}}>{template.description}</h5> 
            </Col>
            <Col md={2} sm={4} style={{textAlign: 'right', paddingRight:'0'}}>
                <Container style={{marginTop:'-5px', padding: '0', marginLeft: '0'}}>
                 {departmentPermission && 
                     <>
                        <Button className="primary-button border-button" style={{height: '35px', width: '38px' , marginRight:'2px'}} onClick={() => openTemplate(template._id)}>
                            <FontAwesomeIcon icon={faPen}  size='1x' className='all-font-icons' style={{fontSize: '13px', marginBottom: '2px'}}/> 
                        </Button>

                        <Button variant="danger" style={{height: '35px', width: '38px' }} onClick={() => {openRemoveTemplate(true); setTemplateID(template._id) }}>
                            <FontAwesomeIcon icon={faTrash}  size='1x' className='all-font-icons' style={{fontSize: '13px', marginBottom: '2px'}}/> 
                        </Button>
                    </>
                }
                </Container>
            </Col>
    
        </Row>

        <Row stylw={{height: "20px"}}>
            <h5 className='select-section-text'>View Section</h5>
        </Row>
        <Row style={{marginBottom: "10px"}}>
            <div className="template-sections-select">
                <Select
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    value={{label:chosenSection}}
                    options={sections}
                    onChange={(e) => {setChosenSection(e.value)}}                
                />
            </div>
            
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="button-tooltip">
                        Open Section
                    </Tooltip>
                }
                >
                <Button className="open-section-button" variant="light" style={{height: '35px', width: '38px' , marginRight:'2px'}} onClick={() => OpenSection(chosenSectionID)}>
                    <OpenInNewIcon className="open-section-icon"/>
                </Button>
            </OverlayTrigger>

         
            
        </Row>

       
        <Row>
            <iframe src={"https://view.officeapps.live.com/op/embed.aspx?src=" + "http://machreporter.co.uk/sections/" + chosenSectionID + "/doc"}  width="100%" height="500" frameborder='1'>This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe>
        </Row>

        <Row style={{paddingTop: '8px', paddingBottom: '2px'}} >
            <Col>
                <h5 style={{fontSize: '14px', color: '#7C7C7C'}}>
                {Object.keys(users).map(u => {
                    return users[u]._id === template.last_updated_by && "Last updated by " + users[u].name
                })}
                </h5>
            </Col>
        </Row>

    </Container>
  )
}

export default TemplateWidget
