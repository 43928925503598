import React, { useState } from 'react'
import { Modal, Form, Row, Col, InputGroup, Button, Spinner } from 'react-bootstrap'
import axios from 'axios'
import { endpoint } from '../../../utils/api';
import SaveButton from '../../../elements/SaveButton';
//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature, faAt, faKey, faUserShield, faUserFriends, faUserPlus, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

//CSS
import '../../../css/icons/stylingIcons.css'
import '../../../css/components/users.css'

library.add(faFileSignature, faAt, faKey, faUserShield, faUserFriends, faUserPlus, faEye, faEyeSlash)

const NewUserModal = ({users, userID, newusermodal, openNewUser, userCreated, userUpdated, userError, callUsers }) => {

    let selectedUser = {}
    Object.keys(users).map((user) => { 
        if(users[user]._id === userID){
            selectedUser = users[user]           
        }
     });

    const [validated, setValidated] = useState(false)
    const [showpassword, setShowPassword] = useState(false)
    const [selectedRole, setSelectedRole] = useState(selectedUser.role)
    const [loading, setLoading] = useState(false)

    const roles  = [
        {
            label: "User",
            name: "User"
        },
        {
            label:"Global Admin",
            name: "Admin"
        }
    ]

    const deactivation = [
        {
            label: "Yes",
            name: false
        },
        {
            label: "No",
            name: true
        }
    ]

    const handleSubmit = async (e, props) => {
        const form = e.currentTarget
        setLoading(true)
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            setValidated(true)
            setLoading(false)
        } else {
            e.preventDefault()
            const formData = new FormData(e.target),
                    formDataObj = Object.fromEntries(formData.entries())

            let method
            if (userID === '') {
                method = "post"
            } else {
                method = "put"
            }
            await axios({
                method: method,
                url: `${endpoint}/users`, 
                data: {
                    name: formDataObj.name,
                    email: formDataObj.email,
                    password: formDataObj.password,
                    role: formDataObj.role,
                    user_id: formDataObj.user_id,
                    is_deactivated: formDataObj.is_deactivated
                } ,
                headers: {
                    Authorization: 'Bearer '+ localStorage.token
                }
                })
                .then(function (response) {
                    if (response.status === 200) {             
                        if (userID === ''){
                            userCreated(true)
                        } else {
                            userUpdated(true)
                        }
                        openNewUser(false)
                        callUsers();
                        setLoading(false)
                    } else {
                        console.log(response.statusText)  
                        console.log(response.errors)   
                        userError(true)   
                        openNewUser(false) 
                        setLoading(false)           
                    }
                })
                .catch((error) => {
                    console.log(error)
                    userError(true)   
                    openNewUser(false)
                    setLoading(false)
                })

        }
    }

    const changePasswordShow = () => {
        setShowPassword(!showpassword);
    }
    const chosenRole = (role) => {
        setSelectedRole(role)
    }
   
    return (
        <Modal show={newusermodal} onHide={() => openNewUser(false)}>
            <Modal.Header className="heading" closeButton>
                <Modal.Title>{userID !== '' ? 'Edit User' : 'Add New User'}</Modal.Title>
            </Modal.Header>

            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Modal.Body>

                <Form.Control type="hidden" name="user_id" defaultValue={userID !== '' ? userID : ''}/>

                        <Form.Group as={Col} controlId="formGridDate">
                            <Form.Label>Name</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FontAwesomeIcon icon={faFileSignature}  size='1x' className='all-font-icons' /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="Name..." name="name" defaultValue={userID !== '' ? selectedUser.name : ''} required/>
                                <Form.Control.Feedback type="invalid">
                                    Please choose a name.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridDate">
                            <Form.Label>Email</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FontAwesomeIcon icon={faAt}  size='1x' className='all-font-icons' /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="Email..." name="email" defaultValue={userID !== '' ? selectedUser.email : ''} required/>
                                <Form.Control.Feedback type="invalid">
                                    Please choose an email.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridDate">
                            <Form.Label>Password</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FontAwesomeIcon icon={faKey}  size='1x' className='all-font-icons' /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type={!showpassword && "password"} placeholder="Password..." name="password" defaultValue={userID !== '' ? "" : ''} required={userID === ''}/>                              
                                <Form.Control.Feedback type="invalid">
                                    Please input a password.
                                </Form.Control.Feedback>
                                <InputGroup.Append>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon 
                                                    icon={!showpassword  ? faEye : faEyeSlash}
                                                    size='1x' 
                                                    className={!showpassword ? 'all-font-icons pw-show': 'all-font-icons pw-hide'} 
                                                    onClick={changePasswordShow}
                                                    />
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridContactName">
                            <Form.Label>Role</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FontAwesomeIcon icon={selectedRole === "Admin" ? faUserShield : faUserFriends}  size='1x' className={selectedRole === "User" ? "all-font-icons users-icon" : "all-font-icons" }/></InputGroup.Text>
                                </InputGroup.Prepend>
                                    <Form.Control as="select" name="role" onChange={(e) => {chosenRole(e.target.value)}} >
                                        {roles.map((option) => {
                                            if(userID !== ''){                                                                                     
                                                return <option selected={selectedUser.role === option.name} value={option.name} >{option.label} </option>
                                            } else {
                                                return <option value={option.name}>{option.label}</option>
                                            }
                                        })}                             
                                    </Form.Control>
                            </InputGroup>
                        </Form.Group>  

                        {selectedUser.is_deactivated === true && (
                         <Form.Group as={Col} controlId="formGridContactName">
                         <Form.Label>Change Deactivation?</Form.Label>
                         <InputGroup className="mb-2">
                             <InputGroup.Prepend>
                                 <InputGroup.Text><FontAwesomeIcon icon={faUserPlus}  size='1x' className='all-font-icons' /></InputGroup.Text>
                             </InputGroup.Prepend>
                                 <Form.Control as="select" name="is_deactivated" >
                                     {deactivation.map((option) => {                                                                                  
                                        return <option selected={option.name} value={option.name}>{option.label}</option>
                                     })}                             
                                 </Form.Control>
                         </InputGroup>
                     </Form.Group> 
                )}    

                </Modal.Body>

                <Modal.Footer className="footer">
                    <Button variant="secondary" onClick={() => openNewUser(false)}>Close</Button>
                    <SaveButton loading={loading} />
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default NewUserModal