import React, {useEffect, useState} from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { endpoint, getSection, getUsers, getSections, getCategories, getDepartmentPermissions } from '../../utils/api'
import { useParams } from 'react-router-dom';
import RevisionCard from './RevisionCard'
import SectionModal from './SectionModal'
import RemoveSectionModal from './RemoveSectionModal';
import ChangeRevisionModal from './ChangeRevisionModal';
import AlertComponent from '../alerts/AlertComponent'
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";

import '../../css/components/sections.css'

library.add(faPen, faTrash)
const Section = () => {
    const history = useHistory();

    const [openNewModal, setOpenNewModal] = useState(false);
    const [removesectionmodal, openRemoveSection] = useState(false)
    const [changerevisionmodal, openChangeRevision] = useState(false);
    const [sectioncreated, setSectionCreated] = useState(false);
    const [sectiondeleted, sectionDeleted] = useState(false);
    const [sectionupdated, setSectionUpdated] = useState(false);
    const [sectionerror , sectionError] = useState(false);
    const [categories, setCategories] = useState([]);
    const [chosenCategory, setChosenCategory] = useState(null);
    const [sections, setSections] = useState([]);
    const [section, setSection] = useState(false); 
    const [users, setUsers] = useState(false);
    const [revisions, setRevisions] = useState([]);
    const [departmentPermission, setDepartmentPermission] = useState(false) 

    const { id, department } = useParams();
    const [revisionSelected, setRevisionSelected] = useState(null)

    async function getRevisions()
    {
        //load section
        let response = await fetch(`${endpoint}/sections/section/${id}/revisions`, 
         {
        headers: {
            Authorization: 'Bearer '+ localStorage.token,
            departmentName: department
        }
        }
        );        
       
        let j = await response.json();
    
        setRevisions(j);
    }

    useEffect(() => { 
        getUsers(setUsers);
        getRevisions();
    }, []);   
    
    useEffect(() => {
        if (department !== undefined) {
            getSection(setSection, id, department);
            getCategories(setCategories, department);
            getSections(setSections, department, "All")
            getDepartmentPermissions(setDepartmentPermission, department)
        }
    }, [department])
  
    useEffect(() => {
            revisions.map(r => { 
                r.revision === section?.revision && setRevisionSelected( r._id)
            })  
     
    }, [revisions, section])
  
    useEffect(() => {
        sectiondeleted && history.push(`/sections`);
    }, [sectiondeleted])

    return (
        <Container className="main-page" fluid>
                    {sectioncreated && <AlertComponent type="success" text="Section Created!"/>}
                    {sectionupdated && <AlertComponent type="primary" text="Section Updated!"/>}
                    {sectionerror && <AlertComponent type="warning" text="Error"/>}
                    {sectiondeleted && <AlertComponent type="danger" text="Section Deleted"/>}
                <Row className="row-header" style={{width: '100%'}}>  
                            <Col lg={9} sm={6}>
                                <Container style={{marginLeft: '0'}}>
                                    <h2 style={{textAlign: 'left',fontSize: '23px'}}>{section.name}</h2>
                                    <h5 style={{textAlign: 'left', color: '#504F4F', fontSize: '17px'}}>{section.description}</h5>
                                </Container>
                            </Col>
                            <Col lg={3} sm={6} className="buttons">
                              
                                <Container style={{marginTop: '20px', marginLeft: '30px'}}>
                                    {departmentPermission &&
                                        <>
                                        <Button className="primary-button edit-button border-button" type="submit" onClick={() => setOpenNewModal(true)} >
                                            <FontAwesomeIcon icon={faPen}  size='1x' className='all-font-icons' />  Edit
                                        </Button>

                                        <Button className="bin-button" variant="danger" onClick={() => openRemoveSection(true)}>
                                            <FontAwesomeIcon icon={faTrash}  size='1x' className='all-font-icons' />  Bin
                                        </Button>
                                        </>
                                    }
                                </Container>
                            </Col>
                        <hr style={{backgroundColor: '#C8C8C8', width: '100%', height: '2px', marginTop: '0', marginLeft: '30px'}}></hr>                 
                </Row>  

                <Container fluid>
                    <Row style={{maxHeight: '750px'}}>
                        <Col lg={3} md={4}>   
                            <div className="section-widget-header"  >
                                        <h3 className="widget-header-text">Revisions</h3>
                            </div>       
                            <div className="section-widget" style={{padding: '10px', overflowY: 'auto', maxHeight:'699px'}} >
                                {revisions.map( r => {
                                    return <RevisionCard revision={r} users={users} setRevisionSelected={setRevisionSelected} revisionSelected={revisionSelected} openChangeRevision={openChangeRevision} section={section} />
                                }
                                )}
                            
                            </div>
                        </Col>
                        <Col lg={9} md={8}>        
                            <div className="section-widget-header"  >
                                    <h3 className="widget-header-text">Preview</h3>
                            </div>                                    
                            <div className="section-widget" >
                        
                            {revisionSelected !== null &&
                            <iframe src={"https://view.officeapps.live.com/op/embed.aspx?src=" + "http://machreporter.co.uk/sections/" + revisionSelected + "/doc"}  width="100%" height="650" frameborder='0'>This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe>
                            }
                            </div>
                                
                        </Col>
                    </Row>
                </Container>
                    {openNewModal && 
                    <SectionModal 
                        openNewModal={openNewModal} 
                        setOpenNewModal={setOpenNewModal} 
                        setSectionCreated={setSectionCreated} 
                        sectionError={sectionError} 
                        setSectionUpdated={setSectionUpdated}
                        getCurrentSection={getSection}
                        setCurrentSection={setSection}
                        sectionID={id}
                        sections={sections}
                        categories={categories}
                        chosenDepartment={department}
                        />
                    }
                    {removesectionmodal &&
                    <RemoveSectionModal 
                        sectionID={id}
                        removesectionmodal={removesectionmodal}
                        openRemoveSection={openRemoveSection}
                        sectionDeleted={sectionDeleted}
                        sectionError={sectionError}
                        getSections={getSections}
                        setSections={setSections}
                        department={department}
                        category={chosenCategory}
                    />  
                    }
                    {changerevisionmodal &&
                    <ChangeRevisionModal
                        sectionID={id}
                        revisionID={revisionSelected}
                        changerevisionmodal={changerevisionmodal}
                        openChangeRevision={openChangeRevision}
                        department={department}  
                        sectionUpdated={setSectionUpdated}
                        sectionError={sectionError}   
                        getSection={getSection}
                        setSection={setSection}            
                    />
                    }
            
        </Container>
    )
}

export default Section
