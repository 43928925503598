/* eslint-disable no-extend-native */
import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, ButtonGroup } from 'react-bootstrap'
import AlertComponent from '../alerts/AlertComponent'
import CategoriesTable from './CategoriesTable'
import CategoryModal from './CategoryModal'
import RemoveCategoryModal from './RemoveCategoryModal'
import { getCategories, getCategoryStats } from '../../utils/api'
import { checkUserToken } from '../../utils/checkUser';
import CategoryCard from './CategoryCard'
import makeAnimated from 'react-select/animated';
import SearchBar from '../../elements/SearchBar'


//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faShareSquare)
const animatedComponents = makeAnimated();
const Categories = ({chosenDepartment, departmentPermission}) => {

    const [openNewModal, setOpenNewModal] = useState(false);
    const [removecategory, openRemoveCategory] = useState(false);
    const [categorycreated, categoryCreated] = useState(false);
    const [categoryerror, categoryError] = useState(false);
    const [categorydeleted, categoryDeleted] = useState(false)
    const [categoryupdated, categoryUpdated] = useState(false)
    const [chosenView, setChosenView] = useState("card")
    const [categoryStats, setCategoryStats] = useState([])
    const [categories, setCategories] = useState([]);

    const [categoryID , setCategoryID] = useState("")

    const [categoriesVisible, setCategoriesVisible] = useState([]);
    const [searchText, setSearchText] = useState("");


    useEffect(() => {   
        checkUserToken();
    }, []);

    useEffect(() => {
        if (chosenDepartment !== null && chosenDepartment !== undefined) {
            getCategories(setCategories, chosenDepartment);
        }
    }, [chosenDepartment]);

    useEffect(() => {
        if(categories.length > 0) {
            let categoryIDs = [];

            categories.map(c => {
                categoryIDs.push(c._id)
            })

            getCategoryStats(setCategoryStats, categoryIDs)
        }
    }, [categories])

    //Filter Level 1
    useEffect(() => {
        setCategoriesVisible(
            categories.filter((c) => {
            return(
                c.title.toLowerCase().includes(searchText.toLowerCase().trim())
                )
            })
        )          
    }, [searchText, categories]);

    return (
        <Container className="main-page" fluid>
        <Container fluid>
            {categoryupdated && <AlertComponent type="primary" text="Category Updated!"  setShow={categoryUpdated}/>}
            {categorycreated && <AlertComponent type="success" text="Category Created!" setShow={categoryCreated}/>}
            {categorydeleted && <AlertComponent type="danger" text="Category Deleted!" setShow={categoryDeleted}/>}
            {categoryerror !== false && <AlertComponent type="danger" text={categoryerror} setShow={categoryError}/>}
        </Container>

        <Row className="row-header">
                <Col sm={8} xs={9}>
                    <Container>
                        <h2 className="main-header">{chosenDepartment} Categories</h2>
                    </Container>
                </Col>
                <Col sm={4} xs={3} className="buttons">
                    <Container>
                        {departmentPermission &&
                            <Button className="primary-button  border-button" onClick={() => {setOpenNewModal(true); setCategoryID("")}} >
                                <FontAwesomeIcon icon={faPlusSquare}  size='1x' className='all-font-icons' />  New
                            </Button>
                        }
                    </Container>
                </Col>
        </Row>

        <Container fluid>
                <hr style={{backgroundColor: '#C8C8C8', width: '100%', height: '2px', marginTop: '0'}}></hr> 
        </Container>

        <SearchBar 
            setChosenView={setChosenView} 
            chosenView={chosenView} 
            setSearchText={setSearchText} 
            placeholder="Search Categories"
        />

        <Container fluid>
        <Row>

            {chosenView === "card" && categoriesVisible.map((category, index) => {

                return (
                    <Col lg={3} xs={6}>
                        <CategoryCard 
                            category={category} 
                            setOpenNewModal={setOpenNewModal} 
                            openRemoveCategory={openRemoveCategory} 
                            setCategoryID={setCategoryID}
                            departmentPermission={departmentPermission}
                        />
                    </Col>
                )
            })}
                              


              {(categoryStats.length > 0 && chosenView === "table" ) &&
              <Container fluid>
                <CategoriesTable 
                    data={categoriesVisible} 
                    EditModal={setOpenNewModal} 
                    SetID={setCategoryID} 
                    RemoveModal={openRemoveCategory} 
                    stats={categoryStats}
                    departmentPermission={departmentPermission}
                    />
              </Container>
               }
        </Row>
        </Container>
         

            {openNewModal && 
            <CategoryModal 
                openNewModal={openNewModal} 
                setOpenNewModal={setOpenNewModal} 
                categoryCreated={categoryCreated} 
                categoryUpdated={categoryUpdated}
                categoryError={categoryError}  
                getCategories={getCategories} 
                setCategories={setCategories}
                categoryID={categoryID} 
                categories={categories}
                chosenDepartment={chosenDepartment}
                />
            }
            {removecategory && 
            <RemoveCategoryModal 
                categoryID={categoryID} 
                removecategory={removecategory} 
                openRemoveCategory={openRemoveCategory} 
                categoryDeleted={categoryDeleted} 
                categoryError={categoryError} 
                getCategories={getCategories} 
                setCategories={setCategories}
                chosenDepartment={chosenDepartment}
                stats={categoryStats}
                />
            }


        </Container>
    )
}

export default Categories