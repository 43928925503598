import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import AlertComponent from '../alerts/AlertComponent'
import {FormControl, InputLabel, InputAdornment, FilledInput, Select, Box, MenuItem } from '@mui/material'
import SectionModel from './SectionModal';
import { useHistory } from "react-router-dom";
import { getSections, getTags, getCategories, getFileSizes } from '../../utils/api'
import RemoveSectionModal from './RemoveSectionModal'
import { checkUserToken } from '../../utils/checkUser';
import SectionCard from './SectionCard'
import SectionsTable from './SectionsTable'

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faSearch)

const Sections = ({chosenDepartment, departmentPermission}) => {
    const history = useHistory();
    const [openNewModal, setOpenNewModal] = useState(false);
    const [removesectionmodal, openRemoveSection] = useState(false)
    const [sectiondeleted, sectionDeleted] = useState(false);
    const [sectionupdated, setSectionUpdated] = useState(false);
    const [sectionerror , sectionError] = useState(false)
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const [sectionID, setSectionID] = useState('')
    const [fileSizes, setFileSizes] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [searchText, setSearchText] = useState("");
    const [chosenCategory, setChosenCategory] = useState("All")
    const [sections, setSections] = useState([])
    const [sectionsVisible, setSectionsVisible] = useState([]);
    const [sectionsFromSearch, setSectionsFromSearch] = useState([]);
    const [chosenView, setChosenView] = useState("card")
    const [tagsVisible, setTagsVisible] = useState([]);

        
    const handleClicked = (id) => {
        history.push(`/sections/${chosenDepartment}/${id}`);
    }
    useEffect(() => {
        let arr = [];
        tags.map((tag) => {
            let dudObj = {
                value:"",
                label:""
            }
            dudObj.value = tag.name
            dudObj.label = tag.name
            arr.push(dudObj)
        }) 
        setTagsVisible(arr);
    }, [tags])

    useEffect(() => { 
        checkUserToken();
    }, []);

    useEffect(() => {
        let Ids = []
        sections.map(s => {
            Ids.push(s._id)
        })
        console.log(Ids)
        if (Ids.length > 0){
            getFileSizes(chosenDepartment, Ids, setFileSizes)
        }
    }, [sections, chosenDepartment])

    useEffect (() => {
        if (chosenDepartment !== null && chosenDepartment !== undefined) {
          setChosenCategory("All");
          getCategories(setCategories, chosenDepartment);
          getSections(setSections, chosenDepartment, chosenCategory);
          getTags(setTags, chosenDepartment);
        }
    }, [chosenDepartment])

    useEffect(() => {
          if(chosenDepartment !== undefined && chosenDepartment !== null) {
            getSections(setSections, chosenDepartment, chosenCategory); 
    }
    }, [chosenCategory]);

    //Filter Level 1
    useEffect(() => {
        setSectionsFromSearch(
          sections.filter((section) => {
            const tags = section.tags.toString();

            return(
                section.name.toLowerCase().includes(searchText.toLowerCase().trim()) ||
                section.description.toLowerCase().includes(searchText.toLowerCase().trim()) ||
                // selectedTags !== null && section.tags.some(r=> selectedTags.indexOf(r) >= 0)
                tags.toLowerCase().includes(searchText.toLowerCase().trim())
              )
          })
        )          
    }, [searchText, sections]);

    useEffect(() => {
        setSectionsVisible(
          sectionsFromSearch.filter((section) => {
            return(
                selectedTags.length === 0 ? true : section.tags.some(r=> selectedTags.indexOf(r) >= 0)
              )
          })
        )          
    }, [selectedTags, sectionsFromSearch]);
    
    console.log(chosenCategory);

    return (
        <Container className="main-page" fluid>
            <Container fluid> 
                {sectiondeleted && <AlertComponent type="danger" text="Section moved to bin!" setShow={sectionDeleted}/>}
                {sectionerror && <AlertComponent type="danger" text="Error" setShow={sectionError}/>}
                {sectionupdated && <AlertComponent type="primary" text="Section Updated!" setShow={setSectionUpdated}/>}
            </Container>
            
            <Row className="row-header">
                    <Col sm={8} xs={9}>
                        <Container>
                            <h2 className="main-header">{chosenDepartment} Sections</h2>
                        </Container>
                    </Col>

            </Row>

            <Container fluid>
                    <hr style={{backgroundColor: '#C8C8C8', width: '100%', height: '2px', marginTop: '0'}}></hr> 
            </Container> 

            <Container fluid >
                <Row style={{marginBottom: "15px", paddingLeft: "15px"}}>

                    <Col lg={6} md={12} sm={12} xs={12} className="view-col">
                            <div className="card-view-button view-button" style={{fontWeight: chosenView === "card" && "550"}} onClick={() => setChosenView("card")}>
                                {"Card View"}
                            </div>
                            <div className="view-button" style={{fontWeight: chosenView === "table" && "550"}} onClick={() => setChosenView("table")}>
                                Table View
                            </div>
                    </Col>

                    <Col lg={3} md={6} sm={6} xs={6} >
                    <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth className="category-select">
                        <InputLabel id="demo-simple-select-label" style={{fontSize: "14px"}}>Category</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={chosenCategory}
                                label="Category"
                                onChange={(e) => setChosenCategory(e.target.value)}
                                style={{fontSize: "14px"}}
                            >
                                <MenuItem value={"All"} className="category-option">All</MenuItem>
                                {categories.map(c => {
                                    return   <MenuItem value={c.title} className="category-option">{c.title}</MenuItem>
                                })}
                      
                            </Select>
                        </FormControl>
                    </Box>
                    </Col>

                    
                    <Col lg={3} md={6} sm={6} xs={6}>
                        
                        <FormControl variant="filled" style={{width: "100%", marginRight: '12px'}} onChange={(e) => setSearchText(e.target.value)} >
                                <InputLabel htmlFor="input-with-icon-adornment" className="search-placeholder">
                                    Search Name, Description, and Tags
                                </InputLabel>
                                <FilledInput
                                id="input-with-icon-adornment"
                                className="search-bar"
                                endAdornment={
                                    <InputAdornment position="end" style={{color: '#224166'}}>
                                        <FontAwesomeIcon icon={faSearch} className="fontWidgetIcon" size='1x' />
                                    </InputAdornment>
                                }
                                />
                        </FormControl>
                    </Col>
    
                </Row>
            </Container>

          
            <Container fluid>
                <Row>
                    {chosenView === "card" && sectionsVisible.map((s, index) => {

                        let sectionFileSize = [];

                        fileSizes.map(f => {
                            s._id == f.id &&  sectionFileSize.push(f.fileSize)
                            
                        })
    
                        return (
                        <Col lg={3} md={6} xs={6}>
                                <SectionCard 
                                    section={s} 
                                    setSectionID={setSectionID}
                                    fileSize={sectionFileSize[0]}
                                    handleClicked={handleClicked}
                                    departmentPermission={departmentPermission}
                                    setOpenNewModal={setOpenNewModal}
                                    openRemoveSection={openRemoveSection}
                                />
                        </Col>
                        )
                    })}
                </Row>
            </Container>

            {chosenView === "table"  && 
                <Container fluid>
                    <SectionsTable
                        data={sectionsVisible}
                        EditModal={setOpenNewModal}
                        RemoveModal={openRemoveSection}
                        SetID={setSectionID}
                        statsArr={fileSizes}
                        openSection={handleClicked}
                        departmentPermission={departmentPermission}
                    />
                </Container>
            }

                {openNewModal && 
                    <SectionModel 
                        openNewModal={openNewModal} 
                        setOpenNewModal={setOpenNewModal} 
                        sectionError={sectionError} 
                        setSectionUpdated={setSectionUpdated}
                        getSections={getSections}
                        setSections={setSections}
                        sectionID={sectionID}
                        sections={sections}
                        categories={categories}
                        chosenDepartment={chosenDepartment}
                        chosenCategory={chosenCategory}
                        />
                    }
                {removesectionmodal &&
                    <RemoveSectionModal 
                        sectionID={sectionID}
                        removesectionmodal={removesectionmodal}
                        openRemoveSection={openRemoveSection}
                        sectionDeleted={sectionDeleted}
                        sectionError={sectionError}
                        getSections={getSections}
                        setSections={setSections}
                        department={chosenDepartment}
                        category={chosenCategory}
                    />  
                }


        </Container>
    )
}

export default Sections