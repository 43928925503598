import React, {useState} from 'react'
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios'
import { endpoint } from '../../../utils/api';
import DeleteButton from '../../../elements/DeleteButton';

const RemoveDepartmentModal = ({ departmentID, removedepartment, openRemoveDepartment, departmentDeleted, departmentError, callDepartments }) => {
    const [loading, setLoading] = useState(false)

    const removeDepartment = async () => {
        setLoading(true)

        await axios({
                    method: "DELETE",
                    url: `${endpoint}/departments/${departmentID}`,
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token
                    }
                })
                .then(function (response) {
                    if (response.status === 200) {
                        console.log("Message Sent.")
                        console.log(response)                      
                        departmentDeleted(true)
                        openRemoveDepartment(false)
                        callDepartments();
                        setLoading(false)
                    } 
                })
                .catch((error) => {
                    departmentError(error.response.data.msg)
                    openRemoveDepartment(false)
                    setLoading(false)
                })
    }

    return (
        <Modal show={removedepartment !== false ? true : false} onHide={() => openRemoveDepartment(false)}>
            <Modal.Header className="delete-header" closeButton>
                <Modal.Title>Disable department</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className="modal-delete-text">Are you sure you want to disable this department?</p>
            </Modal.Body>

            <Modal.Footer className='footer'>
                <Button variant="secondary" onClick={() => openRemoveDepartment(false)}>Close</Button>
                <DeleteButton onClick={removeDepartment} loading={loading} />
            </Modal.Footer>
        </Modal>
    )
}

export default RemoveDepartmentModal