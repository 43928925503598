import React, { useState } from 'react'
import { Modal, Button, Spinner } from 'react-bootstrap'
import axios from 'axios'
import { endpoint } from '../../utils/api'

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const RemoveSectionModal = ({ sectionID, removesectionmodal, openRemoveSection, sectionDeleted, sectionError, getSections, setSections, department, category }) => {

    const [loading, setLoading] = useState(false)

    const removeSection = async () => {
        setLoading(true)
        await axios({
                    method: "DELETE",
                    url: `${endpoint}/sections/${sectionID}`,
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token,
                        DepartmentName: department
                    }
                })
                .then(function (response) {
                    if (response.status === 200) {                   
                        sectionDeleted(true)
                        openRemoveSection(false)
                        getSections(setSections, department, category)
                        setLoading(false)
                    } 
                })
                .catch((error) => {
                    sectionError(error.response.data.msg)
                    openRemoveSection(false)
                    setLoading(false)
                })
    }

    return (
        <Modal show={removesectionmodal !== false ? true : false} onHide={() => openRemoveSection(false)}>
            <Modal.Header className="delete-header" closeButton>
                <Modal.Title >Bin Section</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className='modal-delete-text'>Are you sure you want to move this section to the bin?</p>
            </Modal.Body>

            <Modal.Footer className="footer">
                <Button variant="secondary" onClick={() => openRemoveSection(false)}>Close</Button>
                <Button variant="danger" className="bin-button" onClick={() => removeSection()} disabled={loading}>
                    {loading ? 
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        variant="light"     
                        />
                    :
                    <>
                        <FontAwesomeIcon icon={faTrash}  size='1x' className='all-font-icons' />  Bin
                    </>
                    }      
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default RemoveSectionModal