import React, { useState } from 'react'
import { Modal, Button, Spinner } from 'react-bootstrap'
import axios from 'axios'
import { endpoint } from '../../utils/api'

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const DeleteModal = ({ sectionID, setOpenDeleteSection, openDeleteSection, sectionDeleted, sectionError, getDeletedSections, setDeletedSections, chosenDepartment, setClickedSection }) => {
    const [loading, setLoading] = useState(false);

    const removeSection = async () => {
        setLoading(true)
        await axios({
                    method: "DELETE",
                    url: `${endpoint}/sections/permanent/${sectionID}`,
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token,
                        DepartmentName: chosenDepartment
                    }
                })
                .then(function (response) {
                    if (response.status === 200) {                   
                        sectionDeleted(true)
                        setOpenDeleteSection(false)
                        getDeletedSections(setDeletedSections, chosenDepartment)
                        setClickedSection(null);
                        setLoading(false)
                    } 
                })
                .catch((error) => {
                    sectionError(error.response)
                    setOpenDeleteSection(false)
                    setLoading(false)
                })
    }

    return (
        <Modal show={openDeleteSection !== false ? true : false} onHide={() => setOpenDeleteSection(false)}>
            <Modal.Header className="delete-header" closeButton>
                <Modal.Title >Delete section</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className='modal-delete-text'>Are you sure you want to permanently delete this section and its revisions?</p>
            </Modal.Body>

            <Modal.Footer className="footer">
                <Button variant="secondary" onClick={() => setOpenDeleteSection(false)}>Close</Button>
                <Button variant="danger" className="bin-button" onClick={() => removeSection()} disabled={loading}>
                    {loading ? 
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        variant="light"     
                        />
                    :
                    <>
                        <FontAwesomeIcon icon={faTrash}  size='1x' className='all-font-icons' />  Bin
                    </>
                    }      
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteModal