import { Button, Spinner } from "react-bootstrap"

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const DeleteButton = ({onClick, loading}) => {

    return (
        <Button variant="danger" className="delete-button" onClick={() => onClick()} disabled={loading}>
            {loading ? 
                <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="light"     
                />
                :
                <>
                    <FontAwesomeIcon icon={faTrash}  size='1x' className='all-font-icons' />  Delete
                </>
            }      
        </Button>
    )


}

export default DeleteButton