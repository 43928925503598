import React, { useState } from 'react'
import { Modal, Button, Spinner } from 'react-bootstrap'
import axios from 'axios'
import { endpoint } from '../../../utils/api';
import DeleteButton from '../../../elements/DeleteButton';

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMinus } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

//CSS
import '../../../css/icons/stylingIcons.css'

library.add(faUserMinus)

const RemoveUserModal = ({ userID, removeusermodal, openRemoveUser, userDeactivated, userError, callUsers }) => {
    const [loading, setLoading] = useState(false)

    const removeUser = async () => {
        setLoading(true)
        await axios({
                    method: "DELETE",
                    url: `${endpoint}/users/${userID}`,
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token
                    }
                })
                .then(function (response) {
                    if (response.status === 200) {                    
                        userDeactivated(true)
                        openRemoveUser(false)
                        callUsers();
                        setLoading(false)
                    } 
                })
                .catch((error) => {
                    userError(error.response.data.msg)
                    openRemoveUser(false)
                    setLoading(false)
                })
    }

    return (
        <Modal show={removeusermodal !== false ? true : false} onHide={() => openRemoveUser(false)}>
            <Modal.Header className="delete-header" closeButton>
                <Modal.Title >Deactivate user</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className="modal-delete-text">Are you sure you want to deactivate this user?</p>
            </Modal.Body>

            <Modal.Footer className="footer">
                <Button variant="secondary" onClick={() => openRemoveUser(false)}>Close</Button>
                <Button variant="danger" className="deactivate-button" onClick={() => removeUser()} disabled={loading}>
                    {loading ? 
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        variant="light"     
                        />
                    :
                    <>
                        <FontAwesomeIcon icon={faUserMinus}  size='1x' className='all-font-icons' />  Deactive
                    </>
                    }      
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default RemoveUserModal