import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import { Container, Table, Button } from 'react-bootstrap';

//CSS
import '../../../css/components/members-sidebar.css'

const MembersSidebar = ({usersArr, membersArr, setUpdatedMembers, type}) => {

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const ammendMembers = () => {
    setUpdatedMembers([])
      usersArr.map(user => {
        let usernameString = user.toString()
        var checkUser = document.getElementById(usernameString);
        if (checkUser.checked) {
            setUpdatedMembers((prevMembers) => {
                return [...prevMembers, user]
            })
        }
      })
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400 }}
      role="presentation"

      onKeyDown={toggleDrawer(anchor, false)}
    >
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {usersArr.map((user) => {
                            
                            let checked;
                            if (membersArr.includes(user)){
                                checked=true;
                            } else {
                                checked=false;
                            }
                            return <tr >                                      
                                <td>
                                    <input 
                                        id={user}
                                        type="checkbox"
                                        defaultChecked={checked} 
                                        onChange={() => ammendMembers()}  
                                        />
                                    
                                </td>
                                <td >{user}</td>                
                            </tr> 
                            
                        })}
                    </tbody>
                </Table>
    </Box>
  );
  return (
    <div>
        <React.Fragment key="left">
          <a  className="manage-link" onClick={toggleDrawer("left", true)}>Manage</a>
          <Drawer
            anchor="left"
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
         
          >
            <div className="heading">  
            <h2 className="department-text">{type === "admins" ? "Manage Admins" : "Manage Members"}</h2>
            </div>
            {list("left")}
            <div>
                <div className="phantom-style" />
                <div className="footer-style footer">
                    <Button variant="secondary" className="footer-button" onClick={toggleDrawer("left", false)}>Close</Button>
                </div>
            </div>
          </Drawer>
        </React.Fragment>
    </div>
  );
}

export default MembersSidebar