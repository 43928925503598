import React from 'react'
import { Card, Row, Col, Button} from 'react-bootstrap'
import '../../css/components/tags.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShapes, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

const CategoryCard = ({category, setOpenNewModal, openRemoveCategory, setCategoryID, departmentPermission}) => {   
            
    const handleCardClick = (e) => {

        if (departmentPermission) {
            if (e?.target?.className?.includes("btn-danger")){
                openRemoveCategory(true); 
                setCategoryID(category._id)
            } else {
                setOpenNewModal(true); 
                setCategoryID(category._id)     
            }

        }
    }
     return (
            <Card className="category-card" key={"card-" + category._id.toString()}>
                <Row>
                    <Col xs={2}sm={2} style={{padding: '0'}}>
                        <Card.Body style={{marginTop: '30px'}}>
                                <span className='fa-layers fa-2x fa-fw'>
                                    <FontAwesomeIcon icon={faShapes} className="icon" />
                                </span>                           
                        </Card.Body>
                    </Col>
                    <Col xs={8} sm={8} md ={7} style={{textAlign: 'left', paddingRight: '0', paddingLeft: '10px', position: 'relative', minHeight: '140px'}} >
                        <Card.Body style={{padding: '0', paddingTop: '15px'}}>
                            <Card.Title style={{marginBottom: '6px'}}>{ category.title }</Card.Title>
                            <Card.Subtitle className="tag-updated-by new-line" style={{marginBottom: '20px'}}>
                                { "Last updated by " + category.last_updated_by?.name } 
                            </Card.Subtitle>

                        </Card.Body>
                    </Col>
                    <Col xs={2} sm={1} md={2} style={{padding: '0', textAlign: 'center', paddingLeft: '5px'}}>
                        <Card.Body style={{padding: '0', paddingTop: '30px'}}>
                            {departmentPermission && (
                                <>
                                        <Button className="primary-button border-button" style={{height: '35px', width: '38px', marginBottom: '5px' }} onClick={() => { setOpenNewModal(true); setCategoryID(category._id)}}>
                                            <FontAwesomeIcon icon={faPen}  size='1x' className='all-font-icons' style={{fontSize: '13px', marginBottom: '2px'}}/> 
                                        </Button>

                                        <Button variant="danger" style={{height: '35px', width: '38px' }} onClick={(e) => {openRemoveCategory(true); setCategoryID(category._id) }}>
                                            <FontAwesomeIcon icon={faTrash}  size='1x' className='all-font-icons' style={{fontSize: '13px', marginBottom: '2px'}}/> 
                                        </Button>    
                                </>     
                            )}
                        </Card.Body>
                    </Col>
                </Row>
            </Card>    
    )

}

export default CategoryCard