import React, { useEffect, useState } from 'react'
import { Container, Button, Col, Row, Form, InputGroup, Spinner } from 'react-bootstrap'
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import SectionCard from './SectionCard'
import { endpoint, getCategories, getSections, getTemplate } from '../../utils/api'
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import RemoveTemplateModal from './RemoveTemplateModal';
import AlertComponent from '../alerts/AlertComponent';
import SaveButton from '../../elements/SaveButton';
import '../../css/components/template.css';
import '../../css/components/template/form.css';
import UnAuthUsersTable from '../admin/Users/UnAuthUsersTable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeading, faSitemap, faParagraph, faSave, faShapes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import DeleteButton from '../../elements/DeleteButton';

library.add(faHeading, faParagraph, faSitemap, faSave, faShapes, faTrash)

export const Template = ({departmentPermission}) => {
    const [validated, setValidated] = useState(false);
    const [removetemplatesection, openRemoveTemplate] = useState(false)
    const [templatedeleted, setTemplateDeleted] = useState(false);
    const [selectedSections, setSelectedSections] = useState([]);
    const [allSections, setAllSections] = useState([]);
    const [availableSections, setAvailableSections] = useState([]);
    const [visibleAvailableSections, setVisibleAvailbleSections] = useState([]);
    const [visibleSelectedSections, setVisibleSelectedSections] = useState([]);
    const [template, setTemplate] = useState([]);
    const [searchTextAvailable, setSearchTextAvailable] = useState("");
    const [searchTextSelected, setSearchTextSelected] = useState("");
    const [categories, setCategories] = useState([]);
    const [selectCategory, setSelectCategory] = useState(undefined);
    const [saveLoading, setSaveLoading] = useState(false)
    //Alert Components State
    const [templateupdated, setTemplateUpdated] = useState(false);
    const [templateerror, setTemplateError] = useState(false);
    const [templatecreated, setTemplateCreated] = useState(false);
    const [draggedID, setDraggedID] = useState("");
    const {id, category,department} = useParams();
    const history = useHistory();

    console.log(selectCategory)

    //use effect load in current template

    useEffect(() => {
        if (selectCategory !== undefined) {
            getSections(setAllSections, department, selectCategory); 
        }
        getCategories(setCategories, department); 
        if (id !== "New") {
            getTemplate(setTemplate, id, department);
        }
        if(category !== "All") {
            setSelectCategory(category);
        }

    }, [department])
    useEffect(() => {
            setSelectCategory(template.category?.title);
    }, [template])

    useEffect(() => {
        if (selectCategory !== undefined) {
            getSections(setAllSections, department, selectCategory); 
        }      
    }, [selectCategory])

    //Loads sections for a new template
    useEffect(() => {
        if (id === "New") {
            setAvailableSections(allSections);
        }       
    }, [allSections]);

    //Loads Sections for existing template
    useEffect(() => {
        if (id !== "New" && template.sections !== undefined){
            let templateSectionsIDs = [];
            template.sections.map(s => {
                templateSectionsIDs.push(s._id);
            })
            let sectionsAv = [];
            allSections.map( s => {
                if (!templateSectionsIDs.includes(s._id)){
                    sectionsAv.push(s);
                } 
            })
            setSelectedSections(template.sections);
            setAvailableSections(sectionsAv)
        } else {
            setAvailableSections(allSections);  
        }

    }, [allSections, template])

    // Filtering Level 1 Selected
    useEffect(() => {    
        setVisibleSelectedSections(  selectedSections.filter((section) => {             
            const tags = section.tags.toString();            
            return section.name.toLowerCase().includes(searchTextSelected) || section.description.toLowerCase().includes(searchTextSelected) || tags.toLowerCase().includes(searchTextSelected)         } ));   
    }, [searchTextSelected, selectedSections]);

        // Filtering Level 1 Available
        useEffect(() => {    
            setVisibleAvailbleSections(  availableSections.filter((section) => {             
                const tags = section.tags.toString();            
                return section.name.toLowerCase().includes(searchTextAvailable) || section.description.toLowerCase().includes(searchTextAvailable) || tags.toLowerCase().includes(searchTextAvailable)         } ));   
        }, [searchTextAvailable, availableSections]);

    //search function
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;  
    };

    const onDragStart = (e) => {
       setDraggedID(e.draggableId)
    }
    const id2List = {
       'available':  availableSections,
       'selected': selectedSections
    };

    const getList = id => id2List[id];
    
    const onDragEnd = (result) => {
        setDraggedID("");
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {                       
            const items = reorder(
                getList(source.droppableId),
                source.index,
                destination.index
            );

            if (source.droppableId === 'selected') {
                setSelectedSections(items);
            } else {
                setAvailableSections(items);
            }

        } else {
            const result = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            );

            setSelectedSections(result.selected);
            setAvailableSections(result.available);
        }
    }


    async function handleSubmit(e)
    {


        // post to templates/id
        // post all the details and the new order
        const form = e.currentTarget;
        setSaveLoading(true)
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            setValidated(true)
            setSaveLoading(false)
        } else {
            e.preventDefault();
            const ids = selectedSections.map((section) => {
                return section._id
            })                 
          
            const formData = new FormData(e.target),
                    formDataObj = Object.fromEntries(formData.entries())

            //if category is not selected or all, dialog message and return
            if (selectCategory === undefined || selectCategory === "All") {
                window.alert("Please select a category.")
                setTemplateError(true)
                setSaveLoading(false)
                return;
            }

            let data = {}
            let method, url;
            if (id === "New") {
                method = "POST";
                data = { ids: ids, name: formDataObj.name, description: formDataObj.description, category: formDataObj.category, department: department };
                url = `${endpoint}/templates`
            } else {
                method = "PUT"       
                data = { ids: ids, name: formDataObj.name, description: formDataObj.description, category: formDataObj.category, department: department, template_id: template._id };
                url = `${endpoint}/templates/${template._id}`;
            }
            await axios({
                method: method,
                url: url, 
                data: data,
                headers: {
                    "Content-Type": "application/JSON",
                    Authorization: 'Bearer '+ localStorage.token,
                    DepartmentName: department
                },
            }).
                then(function (response) {
                if (response.status === 200) { 
                    if( id === "New"){
                        setSaveLoading(false)
                        setTemplateCreated(true);
                        history.push(`/templates/${department}/${selectCategory}/${response.data._id}`);
                        window.location.reload();
                    } else {
                        setTemplateUpdated(true);
                        setSaveLoading(false)       
                    }
                            
                } else {
                    console.log(response.statusText)  
                    console.log(response.errors)  
                    setTemplateError(true) 
                    setSaveLoading(false)       
                }
            })
            .catch((error) => {
                console.log(error)
                setTemplateError(true)
                setSaveLoading(false)
            }) 
        }       
    }

    /**
     * Moves an item from one list to another list.
     */
    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    /* Copied from sample - https://codesandbox.io/s/ql08j35j3q?file=/index.js:76-153 */

    //https://codesandbox.io/s/zqwz5n5p9x    

    return (
        <>
          {departmentPermission && 
        <Container className="main-page" fluid >
                <Row className="alert-container">
                    <Col sm={12}>
                    {templateerror && <AlertComponent type="danger" text="Template already exists." setShow={setTemplateError}/>}
                    {templateupdated && <AlertComponent type="primary" text="Template Updated!" setShow={setTemplateUpdated}/>}
                    {templatedeleted  && <AlertComponent type="danger" text="Template Deleted" />}
                    {templatecreated && <AlertComponent type="primary" text="Template Created!" setShow={setTemplateCreated}/>} 
                    </Col>
                </Row>

            <Form onSubmit={handleSubmit} noValidate validated={validated} className="template-form" >

                <Row className="row-header">
                    <Col lg={6} md={8} xs={6}>
                        <Container>
                            <h2 className="main-header">{id === "New" ? "New Template" : "Edit Template"}</h2>
                        </Container>
                    </Col>
                    <Col lg={6} md={4} xs={6} className="buttons">
                        <Container>
                        {id === "New" ? 
                        <>
                            <Button className="primary-button create-button" type="submit" disabled={selectCategory === "All" }>
                                {saveLoading ? 
                                    <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    variant="light"     
                                    />
                                    :
                                    <>
                                    <FontAwesomeIcon icon={faSave}  size='1x' className='all-font-icons' />  Create
                                    </>
                                }       
                            </Button>
                        </>                    
                        :
                        <>
                            <SaveButton loading={saveLoading} />
                            <DeleteButton onClick={() => openRemoveTemplate(true)}/>
                        </>
                        }
                        </Container>
                    </Col>

                </Row>  
                <Container fluid>
                    <hr style={{backgroundColor: '#C8C8C8', width: '100%', height: '2px', marginTop: '0'}}></hr> 
                </Container>   
                
                <Container fluid>
        
                    <Row className="row-widgets">
                        <Col lg={5} md={4}>
                            <Container className="template-widget details-container" fluid>
                                <div className="template-widget-header">
                                    <h3 className="widget-header-text">Details</h3>
                                </div>
                                <Form.Group as={Col} className="form-group">

                                    <Col className="col-md-12 col-xs-12 template-form-col">
                                        <Form.Label className="label">Name</Form.Label>
                                        <InputGroup >
                                        <InputGroup.Prepend>
                                                <InputGroup.Text><FontAwesomeIcon icon={faHeading}  size='1x' className='all-font-icons' /></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control type="text" placeholder="Template Name..." id="name" name="name" defaultValue={id === "New" ? "" : template.name} required/>
                                            <Form.Control.Feedback type="invalid" className="feedback">
                                                Please choose a name.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>     

                                    <Col className="col-md-12 col-xs-12 template-form-col">
                                        <Form.Label className="label">Description</Form.Label>
                                        <InputGroup>
                                        <InputGroup.Prepend>
                                                <InputGroup.Text><FontAwesomeIcon icon={faParagraph}  size='1x' className='all-font-icons' /></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control as="textarea" className="description-area" rows={1} placeholder="Template description..." id="description" name="description" defaultValue={id === "New" ? "" : template.description} required/>
                                            <Form.Control.Feedback type="invalid" className="feedback">
                                                Please choose a description.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>               

                                    
                                    <Col className="col-md-12 col-xs-12 template-form-col"> 
                                        <Form.Label className="label">Category</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text><FontAwesomeIcon icon={faShapes}  size='1x' className='all-font-icons' /></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control as="select" name="category" id="category" onChange={(e) => setSelectCategory(e.target.value)}>
                                                {(template.category === undefined || selectCategory === "All") && <option >Not Selected</option>}
                                                {categories.map((c) => {
                                                    if(template.category !== undefined){ 
                                                        return <option selected={template.category.title === c.title} value={c.name}>{c.title} </option>
                                                    } else if (selectCategory !== "All"){
                                                        return <option selected={selectCategory === c.title} value={c.name} >{c.title} </option>
                                                    } else {
                                                        return <option value={c.title}>{c.title}</option>
                                                    }
                                                })}                             
                                            </Form.Control>
                                        </InputGroup>
                                    </Col> 


                                    <Col className="col-md-12 col-xs-12 template-form-col"> 
                                        <Form.Label className="label">Department</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text><FontAwesomeIcon icon={faSitemap}  size='1x' className='all-font-icons' /></InputGroup.Text>
                                            </InputGroup.Prepend>
                                                <Form.Control type="text" name="department" value={department} defaultValue={department} disabled >                            
                                                </Form.Control>
                                        </InputGroup>
                                    </Col>  
                                </Form.Group> 
                            </Container>
                        </Col>

                        <Col lg={7} md={8}>
                            <Container className="template-widget" fluid>
                                <div className="template-widget-header">
                                    <h3 className="widget-header-text">Sections</h3>
                                </div>
                                    <Container className="draggable-container">
                                        <DragDropContext onDragEnd={onDragEnd} onDragStart={(e) => onDragStart(e)} onDrag >
                                            <Row style={{overflowY: 'auto', height: '72vh'}}>    
                                                <Col lg={6} md={6} className="sections-col">
                                                    <p className="section-col-header">Available Sections</p>
                                                    <Row>
                                                        <Col style={{paddingRight: '5px'}}>
                                                            <Form.Control as="select" name="category" id="category" onChange={(e) => setSelectCategory(e.target.value)}>
                                                            <option selected={selectCategory === "All"} value={"All"} >{"All"} </option>
                                                                {categories.map((c) => {                                                               
                                                                        return <option selected={selectCategory === c.title} value={c.name} >{c.title} </option>
                                                                })}                             
                                                            </Form.Control>
                                                        </Col>
                                                        <Col style={{paddingLeft: '5px'}}>
                                                            <Form.Control onChange={(e) => setSearchTextAvailable(e.target.value)} className="mb-3"  type="text" placeholder="Search available..." />
                                                        </Col>
                                                        
                                                    </Row>
                                                    <Droppable key="available" droppableId="available" className="section-draggable">      
                                                        {provided => (
                                                            <div ref={provided.innerRef}  {...provided.droppableProps}>

                                                            {Object.keys(visibleAvailableSections).map((section) => {
                                                                return <SectionCard 
                                                                            className="section-draggable" 
                                                                            key={visibleAvailableSections[section]._id} 
                                                                            section={visibleAvailableSections[section] } 
                                                                            index={parseInt(availableSections.indexOf(visibleAvailableSections[section]))}
                                                                            number={false}
                                                                            draggedID={draggedID}
                                                                            /> 
                                                                        }
                                                            )}
                                                        
                                                            {provided.placeholder}
                                                            </div>
                                                        )}

                                                    </Droppable>
                                                </Col>


                                                <Col lg={6} md={6} className="sections-col">                        
                                                    <p className="section-col-header">Selected Sections</p>

                                                    <Form.Control onChange={(e) => setSearchTextSelected(e.target.value)} className="mb-3"  type="text" placeholder="Search selected..." />
                                        
                                                
                                                    <Droppable key="selected" droppableId="selected" >      
                                                        {provided => (
                                                            <div ref={provided.innerRef}  {...provided.droppableProps} style={{height: '100%'}}>
                                                            {Object.keys(visibleSelectedSections).map((section) => {
                                                                return <SectionCard  
                                                                            key={visibleSelectedSections[section]._id.toString()} 
                                                                            section={ visibleSelectedSections[section] } 
                                                                            index={parseInt(selectedSections.indexOf(visibleSelectedSections[section])) }
                                                                            number={true}
                                                                            draggedID={draggedID}
                                                                            /> 
                                                                        }
                                                            )}

                                                        
                                                            {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>

                                                </Col>

                                            
                                            </Row>          
                                        </DragDropContext> 
                                    </Container>   
                            </Container>               
                        
                        </Col>           
                    </Row>
                </Container>
            </Form>

            {removetemplatesection && 
            <RemoveTemplateModal 
                templateID={id} 
                removetemplatesection={removetemplatesection}
                openRemoveTemplate={openRemoveTemplate}
                setTemplateDeleted={setTemplateDeleted}
                setTemplateError={setTemplateError}
                department={department}
                chosenCategory={selectCategory}
            />
            }
        </Container>
        }
        {!departmentPermission &&
        <UnAuthUsersTable />
        }  
        </>
    )
}
