import React, { useState } from 'react'
import { Modal, Button, Spinner } from 'react-bootstrap'
//import { newCar, updateCar } from '../../actions/cars'
import axios from 'axios'
import { endpoint } from '../../utils/api'
import '../../css/components/tags.css'

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faBackward)

const RestoreSectionModal = ({openRestoreSection, setOpenRestoreSection, sectionError, sectionUpdated, getDeletedSections, setDeletedSections, sectionID, chosenDepartment, setClickedSection}) => {
    const [validated, setValidated] = useState(false)
    const [loading, setLoading] = useState(false)
    const handleSubmit = async (e, props) => {  
        setLoading(true)
            await axios({
                method: "PUT",
                url: `${endpoint}/sections/section/restore/${sectionID}`, 
                headers: {
                    "Content-Type": "application/JSON",
                    Authorization: 'Bearer '+ localStorage.token,
                    DepartmentName: chosenDepartment
                  },
            })
                .then(function (response) {
                    if (response.status === 200) {
                        setLoading(false)
                        sectionUpdated(true)
                        setOpenRestoreSection(false)
                        getDeletedSections(setDeletedSections, chosenDepartment)
                        setClickedSection(null);
                    } else {
                        console.log(response.statusText)  
                        console.log(response.errors)   
                        sectionError(true)    
                        setOpenRestoreSection(false)    
                        setLoading(false)       
                    }
                })
                .catch((error) => {
                    console.log(error)
                    sectionError(true)    
                    setOpenRestoreSection(false)
                    setLoading(false)
                })
    }
        
    

    return (
        <Modal show={openRestoreSection} onHide={() => setOpenRestoreSection(false)}>
            <Modal.Header className="heading">
                <Modal.Title>Amend Deletion</Modal.Title>
            </Modal.Header>
               
            <Modal.Body>
                <p className='modal-delete-text'>Are you sure you want to restore this section and its revisions?</p>
            </Modal.Body>


            <Modal.Footer className="footer">
                <Button variant="secondary" onClick={() => setOpenRestoreSection(false)}>Close</Button>
                <Button className="restore-button primary-button border-button" onClick={() => handleSubmit()} disabled={loading}>
                    {loading ? 
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="light"     
                        />
                        :
                        <>
                            <FontAwesomeIcon icon={faBackward}  size='1x' className='all-font-icons' />  Restore
                        </>
                    }      
                </Button>  
            </Modal.Footer>
        </Modal>
    )

    }
export default RestoreSectionModal