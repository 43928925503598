/* eslint-disable no-extend-native */
import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import AlertComponent from '../alerts/AlertComponent'
import { endpoint } from '../../utils/api';
import FilesTable from './FilesTable';
import ReportWidget from './ReportWidget'
import ReportModal from './ReportModal'
import RemoveReportModal from './RemoveReportModal'
import { getReports } from '../../utils/api'
import { CheckUserRole, checkUserToken } from '../../utils/checkUser'
import makeAnimated from 'react-select/animated';
import SearchBar from '../../elements/SearchBar';
import axios from 'axios';
//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

//CSS
import '../../css/components/report.css'

library.add(faPlusSquare)
const animatedComponents = makeAnimated();
const Reports = () => {
    const [openNewModal, setOpenNewModal] = useState(false);
    const [removeReport, setOpenRemoveReport] = useState(false)
    const [reportcreated, reportCreated] = useState(false);
    const [reporterror, reportError] = useState(false);
    const [reportdeleted, reportDeleted] = useState(false)
    const [reportupdated, reportUpdated] = useState(false)
    const [reportID , setReportID] = useState("")
    const [chosenView, setChosenView] = useState("card")
    const [reports, setReports] = useState([]);
    const [statsArr, setStatsArr] = useState([])
    const [reportsVisible, setReportsVisible] = useState([]);
    const [searchText, setSearchText] = useState("");

    const {role} = CheckUserRole();

    useEffect(() => { 
        getReports(setReports);
        checkUserToken();
    }, []);


    //Filter Level 1
    useEffect(() => {
        setReportsVisible(
            reports.filter((r) => {
            return(
                r.name.toLowerCase().includes(searchText.toLowerCase().trim())
                )
            })
        )          
    }, [searchText, reports]);

    useEffect(() => {
        let Ids = []
        reports.map(f => {
            Ids.push(f._id)
        })
        Ids.length > 0 && getStatistics(Ids)
    }, [reports])

    async function getStatistics(Ids)
    {

        await axios({
            method: "Post",
            url: `${endpoint}/reports/stats/`, 
            data: {
                Ids: Ids
            } ,
            headers: {
                Authorization: 'Bearer '+ localStorage.token,
            }
            })
            .then(function (response) {
                if (response.status === 200) {
                    setStatsArr(response.data)
                }else{
                    console.log(response.statusText)
                }

        })
    }
    
    const downloadFile = async (id) => {        
        window.open(endpoint+"/reports/download/"+id);
    }

    return (
        <Container className="main-page" fluid>     
            <Container fluid>
                {reportcreated && <AlertComponent type="success" text="Report Created!" setShow={reportCreated}/>}
                {reporterror !== false  && <AlertComponent type="danger" text={reporterror} setShow={reportError}/>}
                {reportdeleted && <AlertComponent type="danger" text="Report Deleted!" setShow={reportDeleted}/>}
                {reportupdated && <AlertComponent type="primary" text="Report Updated!" setShow={reportUpdated}/>}
            </Container>       

            <Row className="row-header">
                <Col sm={8} xs={9}>
                    <Container>
                        <h2 className="main-header">Files</h2>
                    </Container>
                </Col>
                <Col sm={4} xs={3} className="buttons">
                    <Container>
                        {role === "Admin" &&
                            <Button className="primary-button  border-button" onClick={() => {setOpenNewModal(true); setReportID("")}} >
                                <FontAwesomeIcon icon={faPlusSquare}  size='1x' className='all-font-icons' />  New
                            </Button>
                        }
                    </Container>
                </Col>
            </Row>

            <Container fluid>
                    <hr style={{backgroundColor: '#C8C8C8', width: '100%', height: '2px', marginTop: '0'}}></hr> 
            </Container> 

            <SearchBar
                chosenView={chosenView}
                setChosenView={setChosenView}
                setSearchText={setSearchText}
                placeholder="Search Files"
            />

            <Container fluid style={{padding: 0}}>
                <Row >
                {chosenView === "card" && reportsVisible.map((r, index) => {

                    let fileSize = [];

                    statsArr.map(s => {
                        r._id == s.id &&  fileSize.push(s.fileSize)            
                    })

                    return (
                    <Col lg={6}>
                            <ReportWidget 
                                report={r} 
                                setOpenNewModal={setOpenNewModal} 
                                setOpenRemoveReport={setOpenRemoveReport} 
                                setReportID={setReportID} 
                                role={role} 
                                downloadFile={downloadFile}
                                fileSize={fileSize[0]}
                                />                          
                        </Col>
                        )
                    })}
                </Row>

            </Container>

            <Container fluid>
                {chosenView === "table"  && 
                    <FilesTable
                        data={reportsVisible}
                        EditModal={setOpenNewModal}
                        RemoveModal={setOpenRemoveReport}
                        statsArr={statsArr}
                        SetID={setReportID}
                        downloadFile={downloadFile}
                        role={role}
                    />                    
                }
            </Container>
   
           
            {openNewModal && 
            <ReportModal 
                openNewModal={openNewModal} 
                setOpenNewModal={setOpenNewModal} 
                reportCreated={reportCreated} 
                reportUpdated={reportUpdated}
                reportError={reportError}  
                getReports={getReports} 
                setReports={setReports}
                reportID={reportID} 
                reports={reports}
                />
            }

            {removeReport && 
            <RemoveReportModal 
                reportID={reportID} 
                removeReport={removeReport} 
                setOpenRemoveReport={setOpenRemoveReport} 
                reportDeleted={reportDeleted} 
                reportError={reportError} 
                setReports={setReports}
                getReports={getReports} 
                />
            }


        </Container>
    )
}

export default Reports