import React from 'react'
import { Card, Row, Col, Button} from 'react-bootstrap'
import '../../css/components/tags.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTags, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

const TagsCard = ({tag, setOpenRemoveTag, setOpenNewModal, setTagID, departmentPermission}) => {   

    const handleCardClick = (e) => {

        if (departmentPermission) {
            if (e.target?.className.includes("btn-danger")){
                setOpenRemoveTag(true); 
                setTagID(tag._id)
            } else {
                setOpenNewModal(true); 
                setTagID(tag._id)     
            }

        }
    }

    return (
            <Card className="tag-card"  key={"card-" + tag._id.toString()} style={{ width: '100%' }} >
                <Row>
                    <Col xs={2} style={{padding: '0'}}>
                        <Card.Body className="icon-tag">
                                <span className='fa-layers fa-2x fa-fw'>
                                    <FontAwesomeIcon icon={faTags} className="icon" />
                                </span>                           
                        </Card.Body>
                    </Col>
                    <Col xs={7} style={{textAlign: 'left', paddingRight: '0', paddingLeft: '10px'}}>
                        <Card.Body style={{padding: '0', paddingTop: '10px'}}>
                            <Card.Title style={{marginBottom: '6px'}}>{ tag.name }</Card.Title>
                            <Card.Subtitle className="tag-updated-by new-line">
                                { tag.last_updated_by.name + "\n " + tag.updatedAt.slice(0,10).split("-").reverse().join('/') } 
                            </Card.Subtitle>
                                
                        </Card.Body>
                    </Col>
                    <Col xs={2} style={{padding: '0', textAlign: 'center', paddingLeft: '5px'}}>
                        <Card.Body style={{padding: '0', paddingTop: '20px'}}>
                                {departmentPermission && 
                                    <>
                                        <Button className="primary-button border-button" style={{height: '35px', width: '38px', marginBottom: '5px' }} onClick={() => { setOpenNewModal(true); setTagID(tag._id)}}>
                                            <FontAwesomeIcon icon={faPen}  size='1x' className='all-font-icons' style={{fontSize: '13px', marginBottom: '2px'}}/> 
                                        </Button>

                                        <Button variant="danger" style={{height: '35px', width: '38px' }} onClick={() => {setOpenRemoveTag(true); setTagID(tag._id) }}>
                                            <FontAwesomeIcon icon={faTrash}  size='1x' className='all-font-icons' style={{fontSize: '13px', marginBottom: '2px'}}/> 
                                        </Button>
                                    </>
                                }                  
                        </Card.Body>
                    </Col>
                </Row>
            </Card>    
    )

}

export default TagsCard