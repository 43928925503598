
import React, { useEffect, useReducer, useState } from "react";
import { endpoint } from "../../../utils/api";
import { Row, Col } from "react-bootstrap";

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield, faUser } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

const ProfileCard = ({user, handleUserClick, setUserID, stats}) => {
   
	return (
		<div className="card-container" onClick={() => {handleUserClick(); setUserID(user._id)}}>
			<Row>
				<Col> 
			<header className={user.role === "Admin" ? "user-card-header-admin" : "user-card-header-user"}>
                <div className={user.role === "Admin" ? "icon-user-card-admin" : "icon-user-card-user"} >
                    <FontAwesomeIcon  icon={user.role === "Admin" ? faUserShield : faUser}  
                    size='2x' 
                    className={user.role === "Admin" ? 'all-font-icons user-icon-admin' : 'all-font-icons user-icon-logo'}
                    />
                </div>
			</header>
			</Col>
			</Row>
			
			<h1 className="bold-text" style={{marginTop: '35px'}}>
				{user.name}
			</h1>
			<h2 className="normal-text"style={{paddingBottom: user.is_deactivated && "0px", marginBottom: user.is_deactivated && "0px"}} >{user.email}</h2>
			<h2 className="deactivated-text" >{user.is_deactivated ? "Deactivated" : ""}</h2>
			<div className="social-container">
				<div className="followers">
					<h1 className="bold-text">{stats !== null && stats?.activeDepartments}</h1>
					<h2 className="smaller-text new-line">{"Active \n Departments"}</h2>
				</div>
				<div className="likes">
					<h1 className="bold-text">{stats !== null && stats?.templatesMade}</h1>
					<h2 className="smaller-text new-line">{"Created \n Templates"}</h2>
				</div>
				<div className="photos">
					<h1 className="bold-text">{stats !== null && stats?.sectionsMade}</h1>
					<h2 className="smaller-text new-line">{"Created \n Sections"}</h2>
				</div>				
			</div>
	
		</div>
	);
}

export default ProfileCard;