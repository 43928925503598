import React, { Component } from 'react'
import {Switch, Route, Redirect, withRouter} from 'react-router-dom'
import { connect } from 'react-redux'

import Reports from './components/reports/Reports'
import Templates from './components/templates/Templates';
import { Template } from './components/templates/Template';
import Sections from './components/sections/Sections';
import Section from './components/sections/Section';
import Categories from './components/categories/Categories';
import DepartmentsTable from './components/Departments/Departments'
import Users from './components/admin/Users/Users';
import Tags from './components/tags/Tags';
import Bin from './components/bin/Bin';

const Routes = ({chosenDepartment, role, departmentPermission}) => { 

    return (
        <Switch>
        <Route exact path="/">
            <Redirect to="/files" />
        </Route>
        <Route path="/departments" >
              <DepartmentsTable departmentPermission={departmentPermission}/>
          </Route>
        <Route path="/files">
          <Reports/>
        </Route>
        <Route path="/templates/:department/:category/:id">
          <Template departmentPermission={departmentPermission}/>
        </Route>
        <Route path="/templates">
          <Templates chosenDepartment={chosenDepartment} departmentPermission={departmentPermission}/>
        </Route>
        <Route path="/sections/:department/:id">
          <Section/>
        </Route>
        <Route path="/sections">
          <Sections chosenDepartment={chosenDepartment} departmentPermission={departmentPermission}/>
        </Route>   
        <Route path="/categories">
          <Categories chosenDepartment={chosenDepartment} departmentPermission={departmentPermission}/>
        </Route>    
        <Route path="/tags">
          <Tags chosenDepartment={chosenDepartment} departmentPermission={departmentPermission}/>
        </Route>
        <Route path="/examples">
            examples
        </Route>
        <Route path="/users">
            <Users/>
        </Route>
        <Route path="/bin">
            <Bin chosenDepartment={chosenDepartment} departmentPermission={departmentPermission} />
        </Route>
        {/* <Route path="/fees">
            <Fees />
        </Route> */}
       
      </Switch>
    )
}
export default Routes